import React from 'react'
import Select from 'react-select';
import './select.css';

export function SelectField(props) {
    return <Select
            id={props.id}
            placeholder="Selecione"
            className='select-field'
            value={props.value}
            onChange={props.onChange}
            theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'black'
                },
              })}
            options={props.options}
            noOptionsMessage={_ => 'Sem opções encontradas'}
        />
}