import { Environments } from '../constants'

export class Endpoints {
  static AUTHENTICATION = '/public/login'
  static LOGIN_RECOVERY_ENDPOINT = '/public/reset-password'
  static USER = '/user'
  static TIME_RECORD = '/time-record'
  static CHANGE_PASSWORD = '/change-password'

  static getApiUrl() {
    const ENV = Environments.getCurrent()

    return ENV.API_URL
  }
  static getAuthorizationUrl() {
    const ENV = Environments.getCurrent()

    return ENV.API_AUTH_URL
  }
}
