import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const HeaderContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
`;

export const Title =  styled.h1`
  font-size: 2em
  font-weight: bolder
`;

export const StatsPanel = styled.div`
  flex-direction: row;
  display: flex;
  height: 170px;
  width: 900px;
  align-items: center;
  justify-content: center;
`;

export const IdleResources = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  heght: 50px;
  white-space: nowrap;
  background-color: ${
    props => props.color
  };
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
`

export const IdleResourcesTitle = styled.div`
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
`

export const IdleResourcesData = styled.div`
  font-size: 3em;
  font-weight: bold;
`

export const NumberDataContainer = styled.a`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  heght: 50px;
  padding:8px;
  white-space: nowrap;
  color: ${
    props => props.color
  };
`
export const Line = styled.hr``

export const NumberDataTitle = styled.div`
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
`

export const NumberData = styled.div`
  font-size: 1.3em;
  font-weight: bold;
`

export const DataTableContainer = styled.div`
  width: 80%;
  margin: auto;
  padding-top: 30px;
  min-height: 180px;
`;
