import styled from 'styled-components'
import { Close } from 'styled-icons/material/Close'

export const CloseModalButton = styled.div`
  display: ${ props => props.showCloseButton ? 'none' : ''};
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  padding: 0 10px;
`;

export const CloseIcon = styled(Close).attrs(props => ({
  size: 22,
}))`
  margin-right: 10px;
  font-weight: 100;
  &:hover {
    color: red;
    transform: scale(1.3);
  }
`;

let isMinWidthScreen = window.innerWidth <= 768

export const customStyles = {

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },

  content: {
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: 40,

    borderRadius: 10,
    borderWidth: 0.5,
    borderColor: '#278257',
    backgroundColor: ' #E8F0FE',
    alignItems: 'center',
    boxShadow: ' 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
    display: 'flex',
    flexDirection: 'column',
    width: isMinWidthScreen? '75vw': '350px'
  }
}