import React, { useEffect, useState } from 'react'
import { ChangePasswordService } from '../../../services/changePassword/change-password.service'

import {
  Container,
  InputText,
  Button,
  Text,
  ErrorMessage,
  SucessMessage
} from './styles'

const initialState = {
  currentPassword: '',
  newPassword: '',
  confirmationPassword: '',
}

export const ChangePasswordContent = () => {

  const [state, setState] = useState(initialState)
  const [errorMessage, setErrorMessage] = useState('')
  const [sucessMessage, setSucessMessage] = useState('')
  const [sucess, setSucess] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const changePasswordService = new ChangePasswordService()

  useEffect(() => {
    setButtonDisabled(true)

    if (state === initialState) return

    const formComplete =
      !!state.currentPassword.length
      && state.newPassword.length >= 6
      && state.confirmationPassword.length >= 6

    const isEqual = state.newPassword === state.confirmationPassword

    if (isEqual && formComplete) {
      setButtonDisabled(false)
    }
  }, [state.currentPassword, state.newPassword, state.confirmationPassword])

  const onChange = event => {
    const name = event.target.name
    const value = event.target.value

    setState({
      ...state,
      [name]: value
    })
  }

  const changePassword = async () => {
    const request = {
      oldPassword: state.currentPassword,
      password: state.newPassword
    }
    try {
      await changePasswordService.post(request)
      setState(initialState)
      setButtonDisabled(true)
      setSucess(true)
      setSucessMessage('Senha alterada com sucesso!')
    } catch (e) {
      setState(initialState)
      setButtonDisabled(true)
      setSucess(false)
      setErrorMessage('Verifique se sua senha atual está correta e tente novamente!')
      console.log(e)
    }

    console.log(request)
  }

  const inputPassword = (name, value, placeholder) =>
    <InputText
      name={name}
      value={value}
      type={'password'}
      placeholder={placeholder}
      onChange={onChange}
    />

  return (
    <Container>
      <Text>Trocar senha</Text>
      {inputPassword('currentPassword', state.currentPassword, 'Senha atual')}
      {inputPassword('newPassword', state.newPassword, 'Nova senha')}
      {inputPassword('confirmationPassword', state.confirmationPassword, 'Confirmar nova senha')}
      <Button disabled={buttonDisabled} onClick={changePassword}>Enviar</Button>
      {
        sucess ?
          <SucessMessage>{sucessMessage}</SucessMessage> :
          <ErrorMessage>{errorMessage}</ErrorMessage>
      }
    </Container>
  )
}