import React, { useEffect, useState } from 'react';

import { SearchBar } from '../searchbar/searchbar';
import { Menu } from 'styled-icons/boxicons-regular/Menu';
import { logout } from '../../utils/auth'
import { Redirect } from 'react-router-dom'
import Avatar from 'react-avatar';


import {
  Container,
  ContainerButtons,
  RightContainer,
  ContainerLabel,
  Button,
  NavButton,
  DashboardIcon,
  FixedButtons,
  ContentButtonsContainer,
  Logo,
  SearchContainer,
  TruckIcon,
  FileIcon,
  ExitIcon,
  KeyOutlineIcon,
  SupportIcon,
  BarcodeIcon
} from './styles'

export function Navbar(props) {

  const { changePassword } = props

  const [user, setUser] = useState('')
  const [menuOpen, setMenuOpen] = useState(false)
  const [exit, setExit] = useState(false)

  useEffect(() => {
    const userData = localStorage.getItem('user-data')
    if(!userData) {
      logout()
      return <Redirect to="/login" />
    }
    const user = JSON.parse(userData)
    setUser(user)
  }, [])

  function shouldExit() {
    localStorage.clear()
    setExit(!exit)
  }

  function onChangeMenu() {
    setMenuOpen(!menuOpen)
  }

  function renderExitOrHamburger() {
    const isMinWidthScreen = window.innerWidth <= 768

    const searchbar = () => {
      return props.enableSearch && (
        <SearchBar
          loadBySearchTerm={props.loadBySearchTerm}
          showSearchBar={menuOpen && props.enableSearch}
        />
      )
    }

    return <RightContainer> {isMinWidthScreen ?
      
        <>
          <SearchContainer show={menuOpen && props.enableSearch}>{searchbar()}</SearchContainer>
          <Avatar name={user.name} size="50" round={true} color='#026634'/>
          <Button onClick={onChangeMenu}>
            <Menu size="28" title="Menu" />
          </Button>
        </>
        :
        <>
            {searchbar()}
            <Avatar name={user.name} size="50" round={true} color='#026634'/>
            <Button primary onClick={shouldExit} to={'/'}>
              <ExitIcon title="Menu" />
            </Button>
        </>}
      </RightContainer>
  }

  return (
    <Container>
      <ContainerButtons>
        <Logo to='/frotas' src={require('../../assets/img/logo-carmak2.png')} />
        <ContentButtonsContainer open={menuOpen}>
          {user.role != 'ROLE_TECNICO' && <NavButton to='/frotas'>
            <ContainerLabel>
              <TruckIcon title="Frotas"/>
              {
                (window.innerWidth < 768 || window.innerWidth > 1197) && 'Frotas'
              }
            </ContainerLabel>
          </NavButton>}
          {user.role != 'ROLE_TECNICO' && <NavButton to='/chamados'>
            <ContainerLabel>
              <FileIcon title="Chamados" />
              {
                (window.innerWidth < 768 || window.innerWidth > 1197) && 'Chamados'
              }
            </ContainerLabel>
          </NavButton>}

          {user.role != 'ROLE_TECNICO' && localStorage.getItem('user-clients') && <NavButton to='/dashboard'>
            <ContainerLabel>
              <DashboardIcon title="Dashboard" />
              Dashboard
            </ContainerLabel>
          </NavButton>}
          {user.role === 'ROLE_ADMIN' && <NavButton to='/gantt'>
            <ContainerLabel>
              <BarcodeIcon title="Diagrama de Gantt" />
              Diagrama de Gantt
            </ContainerLabel>
          </NavButton>}
          {false && <NavButton to='/helpdesk'>
            <ContainerLabel>
              <SupportIcon title="Suporte"/>
              {
                (window.innerWidth < 768 || window.innerWidth > 1197) && 'Suporte'
              }
            </ContainerLabel>
          </NavButton>}
          {
            window.innerWidth < 768 ? <NavButton onClick={shouldExit} to={'/'}>
              <ContainerLabel>
                <ExitIcon title="Sair" />
                  Sair
              </ContainerLabel>
            </NavButton> : null
          }
          {!!changePassword && !localStorage.getItem('sso-user') &&<NavButton
            onClick={() => {
              changePassword()
              setMenuOpen(!menuOpen)
            }}
          >
          <KeyOutlineIcon title="Trocar Senha" />
          {
            (window.innerWidth < 768 || window.innerWidth > 1197) && 'Trocar senha'
          }
          </NavButton>}
        </ContentButtonsContainer>
      </ContainerButtons>

      {renderExitOrHamburger()}
    </Container>
  )
}