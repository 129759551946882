import React, { useEffect, useState } from 'react';
import 'font-awesome/css/font-awesome.min.css'
import { SearchAlt2 } from 'styled-icons/boxicons-regular/SearchAlt2'

import {
  Form,
  Input,
  ButtonSearch
} from './styles'

let debaunce = null

export const SearchBar = (props) => {

  const [input, setInput] = useState("")
  const [barOpened, setBarOpened] = useState(false)
  
  const formRef = React.createRef()
  const inputFocus = React.createRef()

  const handleClick = e => {
    setBarOpened(false)
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  // Faz a request a cada 1s baseada no termo de busca
  useEffect(() => {
    clearTimeout(debaunce)

    debaunce = setTimeout(() => {
      props.loadBySearchTerm(input)
    }, 1000)

  }, [input])

  // Faz a request quando usuario aperta enter
  const onFormSubmit = e => {
    e.preventDefault();

    props.loadBySearchTerm(input)
    setBarOpened(false);
  };

  return (
    <Form
      barOpened={barOpened}
      onClick={() => {
        setBarOpened(true);
        inputFocus.current.focus();
      }}
      onSubmit={onFormSubmit}
      ref={formRef}
    >
      <ButtonSearch type="submit" barOpened={barOpened}>
        <SearchAlt2 size="25" title="Search"/>
      </ButtonSearch>
      <Input
        type="search"
        onChange={e => setInput(e.target.value)}
        ref={inputFocus}
        value={input}
        barOpened={barOpened}
        placeholder="Buscar..."
      />
    </Form>
  );
}