import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  background: #278257;
  background-image: linear-gradient( to left, #278257, #006838, #004F37 );
  flex-direction: column;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

export const Logo = styled.img`
  background: transparent;
  border-radius: 50px;
  height: auto;
  max-height: 200px;
  max-width: 200px;
  width: auto;
`;

export const MainCard = styled.div`
  align-items: center;
  background: #f2fcf8;
  border: 2px solid #278257;
  border-radius: 10px;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  justify-content: space-around;
`;

export const Card = styled.div`
  align-items: center;
  background: #f2fcf8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  position: relative;
  height: 400px;
  justify-content: space-around;
  width: 300px;
`;

export const InputText = styled.input`
  background: #f2fcf8;
  border: none;
  border-bottom: 2px solid #000000;
  height: 30px;
  width: 250px;
`;

export const Button = styled.button`
  background: transparent;
  border: ${
    props => props.primary ? '2px solid violet' : '2px solid #006838'
  };
  border-radius: 3px;
  color: ${
    props => props.primary ? 'violet' : '#006838'
  };
  cursor: pointer;
  font-size: 16px;
  margin: 0 1em;
  padding: 0.25em 1em;
  transition: 0.5s all ease-out;
  width: 250px;
  &:hover {
    background-color: ${
      props => props.primary ? 'violet' : '#006838'
    };
    color: white;
  }
`;

export const ButtonAzureAd = styled.button`
  border-radius: 3px;
  display:flex;
  align-items:center;
  justify-content:space-around;
  cursor: pointer;
  font-size: 16px;
  margin: 0 1em;
  padding: 0.25em 1em;
  transition: 0.5s all ease-out;
  width: 220px;
  color: #FEFEFE;
  background-color: #007FFF;
  border-color: #007FFF; 
  border-style: solid; 
  &:hover {
    background-color: #e3e6e8;
    color: #007FFF;


  }
`;

export const Text = styled.p`
  color: #006838;
  font-size: 20px;
  font-weight: bold;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
`;

export const SucessMessage = styled.p`
  color: #006838;
  font-size: 14px;
  text-align: center;
`;

export const Link = styled.a`
  color: #006838;
`;

export const Footer =  styled.footer`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height:40px;
  background-color: #f2fcf8;
  color: #006838;
  text-align: center;
  display: flex;
  justify-content:space-around;
  align-items:center;
`;


export const FooterText = styled.p`
  color: #006838;
  font-size: 13px;
  font-weight: bold;
`;