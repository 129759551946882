import BaseService from '../base/base-service'

export default class HelpdeskService extends BaseService {

  async add(obj, image) {

    const formData = new FormData();
    if(image.name) {
      formData.append("fileimage", image);
    }
    formData.append("helpdeskDto", JSON.stringify(obj));
    return await super.postWithMultipartParams(`/helpdesk/add`, formData)
  }

}