import React, { Component, Fragment } from 'react'
import { Navbar } from '../../components'
import ReportsService from '../../services/reports/reports-service'
import 'react-accessible-accordion/dist/fancy-example.css';
import { logout } from '../../utils/auth'
import { Redirect } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import InputMask from 'react-input-mask'
import "react-datepicker/dist/react-datepicker.css"
import { saveAs } from 'file-saver'


import '../../assets/css/custombulma.css'
import 'font-awesome/css/font-awesome.min.css'
import * as S from './styles'

export class Reports extends Component {

  constructor(props) {
    super(props)

    let startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 3)

    this.state = {
        user: {},
        list: [],
        startDate: startDate,
        endDate: new Date(),
        reportType: ''
    };
  }

  componentDidMount() {
    this.reportsService = new ReportsService()
    document.querySelector('.loader').style.visibility = 'hidden'

    const userData = localStorage.getItem('user-data')
    if(!userData) {
      logout()
      return <Redirect to="/login" />
    }
    const user = JSON.parse(userData)
    this.setState({user: user})
  }   

  generatePreventiveReport = async (page = 0) => {

    const { id } = this.props.match.params
    const { startDate, endDate } = this.state

    const formattedStartDate =  startDate.toISOString().slice(0, 10);
    const formattedEndDate =  endDate.toISOString().slice(0, 10);

    const response = await this.reportsService.generatePreventiveReport(id, formattedStartDate, formattedEndDate)

    if (response) {
        const file = new Blob([response], {type: 'application/pdf'});
        saveAs(file, `carmak_preventivas(${formattedStartDate}__${formattedEndDate}).pdf`)
        console.log(response)
    }   
  }

  handleChange = (event) => {

    const target = event.target
    let value = target.value
    const name = target.name

    this.setState({ [name]: value })
  }

  render() {
    const { startDate, endDate, reportType } = this.state

    function isDatesValid(sDate, eDate) {
        return sDate < eDate;
    }

    return (
      <Fragment>
        <Navbar />
        <div style={{ paddingTop: 55, }}>
            <section className="section is-bold">
                <S.Title>Preventivas Realizadas (Vistoriadas)</S.Title>

                <div className="field column">
                    <label className="label" htmlFor="input-data-schedule">
                    Data Inicial:
                    </label>
                    <DatePicker
                    className="control"
                    customInput={
                        <InputMask
                        mask="99/99/9999"
                        value={startDate} />
                    }
                    selected={startDate}
                    onChange={(date) => {
                        this.setState({
                            startDate: date
                        })
                    }}
                    showTimeSelect={false}
                    name="startDate"
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    />
                    
                    <label className="label" htmlFor="input-data-schedule">
                    Data Final:
                    </label>
                    <DatePicker
                    className="control"
                    customInput={
                        <InputMask
                        mask="99/99/9999"
                        value={endDate} />
                    }
                    selected={endDate}
                    onChange={(date) => {
                        this.setState({
                            endDate: date
                        })
                    }}
                    showTimeSelect={false}
                    name="endDate"
                    dateFormat="dd/MM/yyyy"
                    locale="pt"
                    />
                </div>    
                {
              !isDatesValid(startDate, endDate) ?
                <div>
                  <button
                    className="button is-primary is-normal"
                    title="Disabled button"
                    disabled
                  >
                    Gerar Relatório
                </button>
                </div>
                :
                <div className="field" >
                  <button
                    onClick={this.generatePreventiveReport}
                    className="button is-primary is-normal"
                    title="Disabled button"
                  >
                    Gerar Relatório
                </button>
                </div>
            }    
            </section>
        </div>
    </Fragment>
    )
  }
}