import decode from 'jwt-decode';
import moment from 'moment';
export const TOKEN_KEY = "access-token";
export const USER_CLIENTS_KEY = "user-clients";
export const USER_DATA_KEY = "user-data";
export const SSO_USER = "sso-user";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_CLIENTS_KEY);
  localStorage.removeItem(USER_DATA_KEY);
  localStorage.removeItem(SSO_USER);
}

export const isTokenExpired = token => {
  if (!token) {
    window.location.assign('/')
    return false
  }

  var t = token.split(' ').reverse()[0] // remove 'Bearer ' from token
  const { exp } = decode(t)
  var expToSeconds = parseFloat(exp) * 1000

  const expirationDate = moment(new Date(expToSeconds)).subtract(1, 'h').format('DD/MM/YYYY HH:mm:ss')
  const now = moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
  const isExpired = moment(expirationDate).isBefore(moment(now)) 

  console.log('expiration date', expirationDate)
  console.log('date now', now)
  console.log('isEspired', isExpired)

  if (isExpired) {
    localStorage.clear()

    alert('Sessão expirada, É necessario refazer o login.')

    window.location.assign('/')
  }
  return true
}

export const redirectToLogin = () => {
  window.location.assign('/')
}

