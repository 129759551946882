import styled from 'styled-components'
import { CalendarCheck } from 'styled-icons/boxicons-regular/CalendarCheck';

const windowWidth = window.innerWidth 

export const CalendarCheckIcon = styled(CalendarCheck).attrs(() => ({
  size: 22,
}))`
  display: inline;
  margin: 0 20px 0 0;
`;

export const Container = styled.div`
  max-height: 400px;
  text-align: left;
  overflow: auto;
  width: ${ windowWidth > 560 ? '250px' : '100%'};
`;

export const SubContainer = styled.div`
  margin: 0 20px 0 0;
`;

export const Log = styled.li`
  display: inline-block;
`;

export const DateContainer = styled.div`
  margin: 0 0 5px 0; 
  vertical-align: top;
`;

export const LogContainer = styled.div`
  padding: 5px;
  margin: 0 0 10px 0;
  border-radius: 5px;
  border: 1px solid;
`;

export const Title = styled.h1`
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px
`;