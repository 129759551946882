import BaseService from '../base/base-service'
import { logout } from '../../utils/auth'
import { Redirect } from 'react-router-dom'

export default class ResourceService extends BaseService {
    async list(page, searchTerm){

        const clientList = localStorage.getItem('user-clients')
        if (!clientList) {
          return
        }

        return (searchTerm) ?  
            super.get(`/resource/list/clients/?ids=${clientList}&searchTerm=${searchTerm}`) :
            super.get(`/resource/list/clients/?ids=${clientList}&page=${page}`)
    }

    async get(id){
        return super.get(`/resource/${id}`)
    }
}