import styled from 'styled-components'

export const Task = styled.div`
  flex-direction: column;
  display: flex;
`;

export const List = styled.ul`
  padding: 0px 15px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Label = styled.div`
  white-space: nowrap;
  padding: 5px 0;
  font-weight: bold;
`;

export const Description = styled.div`
  padding: 5px;
`;