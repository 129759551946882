import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { isAuthenticated } from "./utils/auth";
import { NewServiceRequest, ServiceRequest, Login, Resources, Helpdesk, History, Reports, Dashboard, Gantt } from "./pages";
import { Loader } from "./components";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Loader />
    <Switch>
      <Route exact path="/" component={ Login } />
      <PrivateRoute path="/frotas" component={ Resources } />
      <PrivateRoute path="/chamados" component={ ServiceRequest } />
      <PrivateRoute path="/novo-chamado/:id" component={ NewServiceRequest } />
      <PrivateRoute path="/historico/:id,:code" component={ History } />
      <PrivateRoute path="/relatorios/:id,:code" component={ Reports } />
      <PrivateRoute path="/helpdesk" component={ Helpdesk } />
      <PrivateRoute path="/dashboard" component={ Dashboard } />
      <PrivateRoute path="/gantt" component={ Gantt } />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;