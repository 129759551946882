import styled from 'styled-components'
import { Star } from 'styled-icons/material/Star';
import { Info } from 'styled-icons/material/Info';

export const StarColor = '#026634'
export const EmptyStarColor = '#999999'
const windowWidth = window.innerWidth 

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column; 
  height: 400px;
  justify-content: space-around;
  width: ${ windowWidth > 560 ? '250px' : '100%'};
`;

export const StarIcon = styled(Star).attrs(props => ({
  size: 22,
}))`
  margin-right: 10px;
  font-weight: 100;
`;

export const InfoIcon = styled(Info).attrs(props => ({
  size: 18,
}))`
  margin-right: 10px;
  font-weight: 100;
  vertical-align: middle;
`;

export const Title = styled.h1`
  text-align: center;
  margin-bottom: 15px;
  font-size: 20px
`;

export const RatingContainer = styled.div`
  margin-top: 10px; 
  margin-bottom: 10px; 
`;

export const TextArea = styled.textarea`
  display: block;
  max-width: 300px;
  min-width: 100%;
  padding: 0.625em;
  resize: vertical;
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  color: #363636;
`;

export const ButtonFeedback = styled.button`
  background-color: #026634;
  border-color: transparent;
  color: #fff;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: none;
  margin-top: 15px;
  font-size: 18px;
`;

export const ButtonLink = styled.button`
  font-size: 20px;
  color: #026634
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  border-radious: 2

  &:hover {
    color: #A9D83C;
  }
`;