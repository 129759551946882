import React, { Component } from 'react';
import '../../../assets/css/custombulma.css';
import 'font-awesome/css/font-awesome.min.css';
import { Link } from "react-router-dom";

export class ResourcesPanel extends Component {

    render() {
        const { list } = this.props

        return (
            list.map(resource => {
                return <div key={resource.id} className="card">
                    <div className="card-content">
                        <div className="field is-horizontal is-grouped">
                            <p className="control is-expanded">
                                <span className="panel-icon">
                                    {
                                        resource.status === 'A'
                                            ? <i className="fa fa-check"></i>
                                            : <i className="fa fa-ban" aria-hidden="true"></i>
                                    }
                                </span>
                                {resource.code} - 
                                <strong>{resource.client.businessName}</strong> - 
                                Loja: <strong>{resource.client.store}</strong> - 
                                {resource.description}
                            </p>
                            <p className="control">
                                <Link className="button is-primary is-small" to={`/novo-chamado/${resource.id}`} >
                                    <i className="fa fa-file-text-o is-hidden-desktop" aria-hidden="true"></i>
                                    <span className="is-hidden-touch"> Abrir Chamado</span>
                                </Link>
                            </p>

                            <p className="control">
                                <Link className="button is-primary is-small" to={`/historico/${resource.id},${resource.code}`} >
                                    <i className="fa fa-history is-hidden-desktop" aria-hidden="true"></i>
                                    <span className="is-hidden-touch"> Histórico</span>
                                </Link>
                            </p>

                            <p className="control">
                                <Link className="button is-primary is-small" to={`/relatorios/${resource.id},${resource.code}`} >
                                    <i className="fa fa-print is-hidden-desktop" aria-hidden="true"></i>
                                    <span className="is-hidden-touch"> Relatórios</span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            })


        )
    }
}