import BaseService from '../base/base-service'

export default class ServiceRequestService extends BaseService {

  async list(page, searchTerm) {
    return (searchTerm) ?
      await super.get(`/request/list?page=${page}&searchTerm=${searchTerm}`, searchTerm)
      :
      await super.get(`/request/list?page=${page}`, searchTerm)
  }

  async listByClient(page, clientId, statusFilter, startDate, endDate) {
    if (statusFilter) {
      return await super.get(`/request/list/client/${clientId}?startDate=${startDate}&endDate=${endDate}&status=${statusFilter}&page=${page}`)
    }
    return await super.get(`/request/list/client/${clientId}?startDate=${startDate}&endDate=${endDate}&page=${page}`)
  }

  async listIdleResourcesByClientId(page, clientId, startDate, endDate) {
    return await super.get(`/request/list/idle-resource/client/${clientId}?startDate=${startDate}&endDate=${endDate}&page=${page}`)
  }

  async getStats(clientId, startDate, endDate) {
    return await super.get(`/request/stats/${clientId}?startDate=${startDate}&endDate=${endDate}`)
  }

  async post(obj) {
    return await super.post(`/request/add`, obj)
  }

  async findFirstMissingFeedback(userId) {
    return await super.get(`/request/find-first-missing-feedback/${userId}`)
  }

  async put(obj) {
    return await super.put(`/request/edit`, obj)
  }
  
  async changeStatus(obj) {
    return await super.post(`/request/change-status`, obj)
  }  
}