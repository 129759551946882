import React, { Component, Fragment } from 'react'
import { Navbar, Feedback, Modal, ChangePasswordContent, Logs } from '../../components'
import ServiceRequestService from '../../services/servicerequest/servicerequest-service'
import { logout } from '../../utils/auth'
import { Redirect } from 'react-router-dom'

import 'font-awesome/css/font-awesome.min.css'
import '../../assets/css/custombulma.css'

export class ServiceRequest extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      list: [],
      page: 0,
      objectInfo: {},
      isConfirmOpened: false,
      serviceRequest: {},
      serviceRequestFeedbackNeeded: {},
      selectedServiceRequest: [],
      hasPendingFeedback: false,
      scrolling: true,
      showResetPwdModal: false,
      showLogsModal: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
    this.serviceRequest = new ServiceRequestService()
    const userData = localStorage.getItem('user-data')
    if(!userData) {
      logout()
      return <Redirect to="/login" />
    }
    this.setState({ user: JSON.parse(userData) })
    this.loadServiceRequest()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  loadServiceRequest = async (page = 0) => {
    const response = await this.serviceRequest.list(page)

    if (!response) return

    const { content, ...objectInfo } = response
    const feedback = await this.serviceRequest.findFirstMissingFeedback(this.state.user.id)

    this.setState({
      list: [...this.state.list, ...content],
      objectInfo,
      page,
      serviceRequestFeedbackNeeded: feedback,
      hasPendingFeedback: !!feedback
    })
  }

  loadMore = () => {
    const { page } = this.state
    this.loadServiceRequest(page)
  }

  loadBySearchTerm = async (searchTerm) => {
    const response = await this.serviceRequest.list(null, searchTerm)

    if (!!response) return

    const { content, ...objectInfo } = response

    this.setState({
      list: [...content],
      objectInfo,
      scrolling: true
    })
  }

  onScroll = () => {
    const { page, scrolling } = this.state
    const { totalPages } = this.state.objectInfo

    if (
      (window.innerHeight + window.scrollY) >=
      (document.body.offsetHeight - 100) &&
      (page < totalPages - 1) && scrolling
    ) {
      this.setState({
        page: page + 1,
        scrolling: false
      }, () => this.loadMore())
    }
  }

  renderItem = (items) => {
    return (
      <div className="panel">
        <p className="panel-heading" >
          Chamados
        </p>
        {
          items.map(item => {
            return <div key={item.id} className="card">
              <header className="card-header">
                <p className="card-header-title">
                  Chamado #{item.id} - {item.codeResource}
                </p>
                <span className="card-header-icon" aria-label="more options">
                  {this.renderStatus(item.status)}
                </span>
              </header>

              <div className="card-content">
                <div className="content">
                  <p style={{ wordWrap: 'break-word' }}>
                    {item.description}
                  </p>
                  <ul>
                    <li>
                      <strong>Aberto em:</strong>
                      <time dateTime="1/1/2016"> {item.createDate}</time>
                    </li>
                    <li>
                      <strong>Agendado para:</strong>
                      <time dateTime="1/1/2016"> {item.scheduleServiceDate}</time>
                    </li>
                    <li>
                      <strong>Status do equipamento:</strong>
                      <time dateTime="1/1/2016"> {item.resourceSituation}</time>
                    </li>
                    {
                      item.resourceSituation === 'PARADO' ?
                        <li>
                          <strong>Data da parada:</strong>
                          <time dateTime="1/1/2016"> {item.stopTime}</time>
                        </li>
                        : <div></div>
                    }
                    <li style={{ wordWrap: 'break-word' }}>
                      <strong>Responsável: </strong>
                      {item.userName}
                    </li>
                    {item.orderSAP && <li style={{ wordWrap: 'break-word' }}>
                      <strong>Ordem SAP: </strong>
                      {item.orderSAP}
                    </li>}
                  </ul>
                </div>
              </div>

              <footer className="card-footer">
                <span
                  className="card-footer-item button is-info is-outlined"
                  onClick={() => this.openLogs(item)}>
                    Detalhes
                </span>
                {this.renderCancel(item)}
              </footer>
            </div>
          })
        }
      </div>
    )
  }

  renderStatus = (status) => {
    return status === 'ABERTO' ?
      <span className="tag is-info">{status}</span>
      :
      <span className="tag is-success">{status}</span>
  }

  renderCancel = (item) => {
    return item.status === 'ABERTO' ?
      <span
        className="card-footer-item button is-danger is-outlined"
        onClick={() => this.openConfirmation(item)}>
          Cancelar
      </span>
      :
      <span
        className="card-footer-item button is-danger is-outlined"
        href="#"
        disabled>
        Cancelar
      </span>
  }

  closeConfirmation = () => {
    this.setState({ isConfirmOpened: false })
  }

  openConfirmation = (item) => {
    this.setState({
      serviceRequest: item,
      isConfirmOpened: true
    })
  }

  changePassword = () => {
    this.setState({ showResetPwdModal: !this.state.showResetPwdModal })
  }
  
  openLogs = (selectedServiceRequest) => {
    this.setState({ showLogsModal: !this.state.showLogsModal , selectedServiceRequest})
  }

  closeLogs = () => this.setState({ showLogsModal: false})

  cancelServiceRequest = async () => {
    const serviceRequest = { ...this.state.serviceRequest }
    let statusChange = {
      id: serviceRequest.id,
      newStatus: 'CANCELADO'
    }
    await this.serviceRequest.changeStatus(statusChange)
    await this.setState({ list: [] })
    await this.loadServiceRequest()
    this.closeConfirmation()
  }

  clearFeedbackData = () => {
    this.setState({
      serviceRequestFeedbackNeeded: {},
      hasPendingFeedback: false,
    })
  }

  renderCofirmation = () => {
    const serviceRequest = this.state.serviceRequest

    return (
      <div className={this.state.isConfirmOpened ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />

        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              Cancelar Chamado #{serviceRequest.id}
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={this.closeConfirmation}
            ></button>
          </header>

          <section className="modal-card-body">
            Deseja realmente cancelar o chamado? Esta ação
            não poderá ser desfeita.
          </section>

          <footer className="modal-card-foot">
            <button
              className="button is-success"
              onClick={this.cancelServiceRequest}
            >
              Confirmar
            </button>
            <button
              className="button"
              onClick={this.closeConfirmation}
            >
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    )
  }

  render() {
    const { list, serviceRequestFeedbackNeeded, showResetPwdModal, showLogsModal , hasPendingFeedback } = this.state

    return (
      <Fragment>

        <Modal isOpen={showResetPwdModal} enableDismiss onAfterClose={this.changePassword}>
          <ChangePasswordContent />
        </Modal>

        <Modal isOpen={hasPendingFeedback} onAfterClose={this.clearFeedbackData}>
          <Feedback serviceRequest={serviceRequestFeedbackNeeded} onAfterSave={this.clearFeedbackData} />
        </Modal>

        <Modal isOpen={showLogsModal} enableDismiss onAfterClose={this.closeLogs}>
          <Logs serviceRequest={this.state.selectedServiceRequest}/>
        </Modal>
 		
		    <Navbar
          loadBySearchTerm={this.loadBySearchTerm}
          enableSearch={true}
          changePassword={this.changePassword} />
        {
          !list[0] ? <div className="container">
            <div className="notification">
              Nenhum Chamado encontrado...
            </div>
          </div>
            :
            this.renderItem(list)
        }
        {this.renderCofirmation()}

      </Fragment>
    )
  }
}