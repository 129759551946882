import axios from 'axios';
import { login, logout } from '../../utils/auth';
import { Endpoints } from '../../config/constants'
import UserService from '../user/user-service'
import ConfigService from '../config/config-service'

const apiUrl = Endpoints.getApiUrl()
const auth = Endpoints.AUTHENTICATION
const recoveryPass = Endpoints.LOGIN_RECOVERY_ENDPOINT
const changePass = Endpoints.CHANGE_PASSWORD
const LOGIN_ENDPOINT = `${apiUrl + auth}`
const RECOVERY_ENDPOINT = `${apiUrl + recoveryPass}`
const CHANGE_PASSWORD = `${apiUrl + changePass}`

export default class LoginService {

  static async login(data) {
    try {
      let response = await axios.post(LOGIN_ENDPOINT, data);

      if (response.status === 200) {
        let accessToken = response.data.accessToken;
        
        if (accessToken) {
          const userService = new UserService();
          login(accessToken);
          let userId = response.data.userId;
          let user = await userService.get(userId);
          let configs = await new ConfigService().getAll();
          localStorage.setItem('user-data', JSON.stringify(user));
          localStorage.setItem('user-clients', user.clients.ids.toString());
          localStorage.setItem('configs',  JSON.stringify(configs));


          let role = response.data.role;
          if(role === "ROLE_ADMIN") {
            let technicians = await userService.listTechnicians()
            localStorage.setItem('technicians', JSON.stringify(technicians));
          } else {
            localStorage.removeItem('technicians');
          }
        } 
      }

    } catch (e) {

      if (e.response.status === 403) {
        throw new Error("Usuário ou senha incorreta. Tente novamente.");
      } else if (e.response.status === 400) {
        throw new Error("Usuário ou senha incorreta. Tente novamente.");
      }
      throw new Error("Não foi possível realizar o login. Tente novamente mais tarde.");
    }
  }

  static async recover(data) {
    try {

      let response = await axios.post(RECOVERY_ENDPOINT, data);

      let accessToken = response.data.accessToken;

      if (response.status === 200 && accessToken) {
        return 'E-mail enviado'
      }

    } catch (e) {
      if (e.response.status === 404) {
        throw new Error("E-mail não encontrado");
      }
      console.log(e)
      throw new Error("Não foi possível realizar a ação. Tente novamente mais tarde.");
    }
  }
}


