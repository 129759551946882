import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: transparent;
  border-style: solid;
  border-width: 0.5px;
  border-color: #00582E;
  min-width: 200px;
  margin: 15px;
  cursor: ${props => (props.barOpened ? "auto" : "pointer")};
  border-radius: 10rem;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  @media (max-width: 768px) {
    min-width: 0;
    margin: 0;
    padding: 0;
    border-radius: 0px 50px 50px 0px;
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #00582E;
    color: #00582E;
    width: ${props => (props.barOpened ? "100%" : "2rem")};
    background-color: ${props => (props.barOpened ? "#00582E" : "transparent")};
  }
`;

export const Input = styled.input`
  font-size: 14px;
  line-height: 1;
  min-width: 350px;
  background-color: transparent;
  margin-left: ${props => (props.barOpened ? "1rem" : "0rem")};
  border: none;
  color: #00582E;
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    color: #00582E;
  }

  @media (max-width: 768px) {
    background-color: ${props => (props.barOpened ? "none" : "none")};
    color: #ffff;
    min-width: 0;
    width: 100%;
    &::placeholder {
      color: #ffff;
    }
  }
`;

export const ButtonSearch = styled.button`
  line-height: 1;
  pointer-events: ${props => (props.barOpened ? "auto" : "none")};
  cursor: ${props => (props.barOpened ? "pointer" : "none")};
  background-color: transparent;
  border: none;
  outline: none;
  color: #00582E;

  @media (max-width: 768px) {
    color: ${props => (props.barOpened ? "#ffff" : "#00582E")};
  }
`;