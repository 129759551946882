import styled from 'styled-components';
import { Link } from 'react-router-dom'
import { Truck } from 'styled-icons/remix-line/Truck';
import { File } from 'styled-icons/octicons/File';
import { Dashboard } from 'styled-icons/octicons/Dashboard';
import { PieChartAlt2 } from 'styled-icons/boxicons-regular/PieChartAlt2';
import { BarChartHorizontal } from 'styled-icons/remix-fill/BarChartHorizontal';
import { Exit } from 'styled-icons/boxicons-regular/Exit';
import { ContactSupport } from 'styled-icons/material/ContactSupport';
import { KeyboardArrowDown } from 'styled-icons/material/KeyboardArrowDown';
import { Key } from 'styled-icons/boxicons-solid/Key';

export const Container = styled.div`
  align-items: center;
  background: #f8fced;
  flex-direction: row;
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-content: center;
  box-shadow: 0 9px 28px rgba(0,0,0,0.30), 0 5px 5px rgba(0,0,0,0.22);
`;

export const ContainerButtons = styled.div`
  align-items: center;
  justify-content:center;
  flex-direction: row;
  display: flex;
  font-size:1.1em;

  @media (max-width: 768px) {
    align-items: start;
  }
`;

export const RightContainer = styled.div`
  align-items: center;
  justify-content: end;
  flex-direction: row;
  display: flex;
`;

export const Button = styled(
  styled(Link)`
    padding: 0 15px;
    border-radius:0.12em;
    transition: 0.3s;
    color: black;
    cursor: pointer; 

    &:hover {
      color: ${ props => props.primary ? 'red' : '#006838'};
      background-color: 'white';
    }
  `)` 
`;

export const ContainerName = styled.p`
  max-width: 400px;
  overflow: hidden;
  display: flex;
`;

export const ContainerLabel = styled.p`
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`;

export const NavButton = styled(Link)`
  font-weight: bold;
  color: #026634;
  padding: 10px;

    &:hover {
    color: 'hotpink';
    background-color: 'white';
  }
  zIndex: -1;
  
  @media (max-width: 768px) {
    padding: 10px;
    background-color: transparent;
    height: 40px;
    width: 100%;
    border-bottom: 0.1px solid black;
  }

`;

export const ChangePasswordButton = styled.div`
  align-items: center;
  display: ${ props => props.changePassOpen ? 'flex' : 'none' };
  box-shadow: 0 9px 28px rgba(0,0,0,0.30), 0 5px 5px rgba(0,0,0,0.22);
  flex-direction: row;
  background-color: #EFFAD7;
  position: absolute;
  top: 40px;
  width: 160px;
  height: 40px;
  padding: 0 10px;
`;

export const Logo = styled.img`
  margin:  0 15px 0 15px;
  min-width: 168px;
  width: 168px;
`;

export const FixedButtons = styled.div`
  display: flex;
  height: 100%;
`;

export const ContentButtonsContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  zIndex: -1000;

  @media (max-width: 768px) {
    display: ${ props => props.open ? 'flex' : 'none' };
    box-shadow: 0 9px 28px rgba(0,0,0,0.30), 0 5px 5px rgba(0,0,0,0.22);
    flex-direction: column;
    background-color: #EFFAD7;
    position: absolute;
    right: 0px;
    top: 50px;
    width: 100%;
    height: 160px;
  }
`;

export const SearchContainer = styled.div`
  @media (max-width: 768px) {
    display: ${props => (props.show ? "none" : "flex")};
    height: 45px;
    width: 90%;
    position: absolute;
    left: 0px;
    top: 50px;
  }
`;

// Icons

export const TruckIcon = styled(Truck).attrs(props => ({
  size: 25,
}))`
  margin-right: 10px;
`;

export const FileIcon = styled(File).attrs(props => ({
  size: 23,
}))`
  margin-right: 10px;
  font-weight: bold;
`;

export const ClockIcon = styled(Dashboard).attrs(props => ({
  size: 25,
}))`
  margin-right: 10px;
  font-weight: bold;
`;

export const BarcodeIcon = styled(BarChartHorizontal).attrs(props => ({
  size: 25,
}))`
  margin-right: 10px;
  font-weight: bold;
`;

export const DashboardIcon = styled(PieChartAlt2).attrs(props => ({
  size: 25,
}))`
  margin-right: 10px;
  font-weight: bold;
`;

export const ExitIcon = styled(Exit).attrs(props => ({
  size: 25,
}))`
  margin-right: -10px;
  font-weight: 100;

  @media (max-width: 768px) { 
    margin-right: 10px;
  }
`;

export const KeyOutlineIcon = styled(Key).attrs(props => ({
  size: 22,
}))`
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 100;
`;

export const ArrowDownIcon = styled(KeyboardArrowDown).attrs(props => ({
  size: 22,
}))`
  margin-left: 5px;
  padding-bottom: 2px;
`;


export const SupportIcon = styled(ContactSupport).attrs(props => ({
  size: 22,
}))`
  margin-left: 5px;
  padding-bottom: 2px;
`;