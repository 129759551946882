import styled from 'styled-components'


export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  margin:50px 0 50px 0;
  border-radius: 5px;
`

export const Name = styled.h1`
  margin:10px 0 10px 0;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.5;
  padding: 10px;
`

export const Label = styled.label`
  margin:10px;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;

`
export const RowContainer = styled.div`
  display: flex;
  border: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  background-color: none
`

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: stretch;
    flex: 2;
    overflow: hidden; 
    box-sizing: border-box;
`


export const Footer = styled.div`
  display: flex; 
  align-items: end;
  justify-content: flex-end;
`
