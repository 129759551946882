import React, { useEffect, useState } from 'react'
import LoginService from '../../services/login/login-service'
import { Redirect } from 'react-router-dom'
import { isAuthenticated, logout } from '../../utils/auth'
import strings from '../../config/locales/br'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/authConfig";
import { Windows } from 'styled-icons/boxicons-logos/Windows'

import {
  Container,
  MainCard,
  Card,
  Logo,
  InputText,
  Button,
  ButtonAzureAd,
  Link,
  Text,
  ErrorMessage,
  SucessMessage,
  Footer,
  FooterText
} from './styles'

export function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [sucess, setSucess] = useState(false)
  const [forgotPassword, setForgotPassword] = useState(false)

  const string = strings.login

  const onChangeEmail = event => setEmail(event.target.value)
  const onChangePassword = event => setPassword(event.target.value)
  const onChangeLogin = () => setForgotPassword(!forgotPassword)

  useEffect(() => {
    document.querySelector('.loader').style.visibility = 'hidden'
  })

  useEffect(() => {
    setEmail('')
    setPassword('')
    setMessage('')
  }, [forgotPassword])

  const login = async () => {
    setMessage('')
    try {
      await LoginService.login({
        email: email,
        password: password
      })
      setSucess(true)
      setEmail('')
      setPassword('')
    } catch (e) {
      setMessage(e.message)
    }
  }

  const loginSso = async (email, ssoToken) => {
    setMessage('')
    try {
      await LoginService.login({
        email: email,
        password: email,
        ssoToken: ssoToken
      })
      localStorage.setItem('sso-user', 'true')
      setSucess(true)
      setEmail('')
      setPassword('')
    } catch (e) {
      setMessage(e.message)
    }
  }


  const recoverPassword = async () => {
    setMessage('')
    try {
      const message = await LoginService.recover({
        email: email
      })
      setSucess(true)
      setEmail('')
      setPassword('')
      const emailMessage = email
      setMessage(`Uma senha temporária foi enviada para ${emailMessage}.`)
      return message
    } catch (e) {
      setMessage(e.message)
    }
  }

  if (isAuthenticated()) {
    const userData = localStorage.getItem('user-data')
    if(!userData) {
      logout()
      return <Redirect to="/login" />
    }
    const user = JSON.parse(userData)
    return user.role != 'ROLE_TECNICO' ? <Redirect to="/frotas" /> : <Redirect to="/helpdesk" />
  }

  const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest)
            .then(data => {
                loginSso(data.account.username, data.accessToken)
            }).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <ButtonAzureAd onClick={() => handleLogin("popup")}><Windows size="25" title="Entrar com Azure AD"/>Entrar com Azure AD</ButtonAzureAd>
    );
}

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
    login();
  }
};

const renderContent = () => {
  return forgotPassword ? (
    <>
      <Text>{string.recover_password}</Text>
      <p>{string.type_your_email}</p>
      <InputText
        value={email}
        type='email'
        placeholder='Email'
        onChange={onChangeEmail}
      />
      <Button onClick={recoverPassword}>Enviar</Button>
      <ErrorMessage>{message}</ErrorMessage>
      <Link onClick={onChangeLogin}>login</Link>
    </>
  ) : (
    <>
      <InputText
        value={email}
        type='email'
        placeholder='Email'
        onChange={onChangeEmail}
        onKeyDown={handleKeyDown}
      />
      <InputText
        value={password}
        type='password'
        placeholder='senha'
        onChange={onChangePassword}
        onKeyDown={handleKeyDown}
      />
      <Button onClick={login}>{string.go}</Button>
      {
        sucess ?
          <SucessMessage>{message}</SucessMessage> :
          <ErrorMessage>{message}</ErrorMessage>
      }
        <Link onClick={onChangeLogin}>{string.forgot_password}</Link> 

      <SignInButton />
    </>
  )
}

return (
  <Container >
    <MainCard>
      <Logo
        src={require('../../assets/img/logo-carmak2.png')}
        alt='Carmak'
      />
      <Text>Chamados</Text>
    
      <Card>
        {renderContent()}
      </Card>
    </MainCard>
    <Footer>
      <FooterText>Carmak Chamados</FooterText>
      <Logo
        src={require('../../assets/img/logo-carmaktoyota.png')}
        alt='Carmak'
      />
      <FooterText>Versão: {process.env.REACT_APP_VERSION}</FooterText>
    </Footer>
  </Container>
  )
}