import React from 'react'
import {
  Container,
  Title,
  Square,
  List,
  Item,
  Description
} from './styles'

export function Legend(props) {

  const plannedItems = props.plannedItems
  const unplannedItems = props.unplannedItems
  return (
    <>
    <Container>
      
        {plannedItems && plannedItems.length > 0 && 
        <>
          <Title>Tarefas Planejadas</Title>
          <List>
              {plannedItems.map(item => 
                  <Item>
                      <Square color={item.color}></Square>
                      <Description>{item.name}</Description>
                  </Item> 
              )}
          </List>
        </>}
        {unplannedItems && unplannedItems.length > 0 && 
        <>
        <Title>Tarefas Não Planejadas</Title>
        <List>
            {unplannedItems.map(item => 
                <Item>
                    <Square color={item.color}></Square>
                    <Description>{item.name}</Description>
                </Item> 
            )}
        </List>
        </>}
    </Container>
    </>
  )
}