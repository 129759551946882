import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'

import {
  customStyles,
  CloseModalButton,
  CloseIcon
} from './styles'
import './styles.css'

export const Modal = (props) => {

  const { children, showCloseButton, isOpen, enableDismiss, onAfterClose } = props

  const [modalOpened, setModalOpened] = useState(false)

  useEffect(() => {
     setModalOpened(isOpen)
  }, [isOpen])

  const closeModal = () => {
    setModalOpened(false)
    onAfterClose()
  }
  const dismissModal = () => {
    enableDismiss && closeModal()
  }

  return (
    <>
      <ReactModal
        htmlOpenClassName="ReactModal__Html--open"
        bodyOpenClassName="ReactModal__Body--open"
        overlayClassName="ReactModal__Overlay"
        isOpen={modalOpened}
        onAfterOpen={() => { }}
        onRequestClose={dismissModal}
        style={customStyles}
      >
        <CloseModalButton showCloseButton={showCloseButton} onClick={closeModal}>
          <CloseIcon />
        </CloseModalButton>
        {children}
      </ReactModal>
    </>
  )
}