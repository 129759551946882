import React from "react";
import Routes from "./routes";
import HttpsRedirect from 'react-https-redirect';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/authConfig";


const msalInstance = new PublicClientApplication(msalConfig);

const App = () =>  <React.StrictMode>
<MsalProvider instance={msalInstance}><HttpsRedirect><Routes /></HttpsRedirect>       </MsalProvider>
    </React.StrictMode>;
export default App;