import React, { Component, Fragment } from 'react'
import { Navbar } from '../../components'
import ServiceRequestService from '../../services/servicerequest/servicerequest-service'
import ResourceService from '../../services/resource/resource-service'
import { Redirect } from "react-router-dom"
import InputMask from 'react-input-mask'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker"
import moment from 'moment'
import pt from 'date-fns/locale/pt'
import { SweetAlert } from '../../components/index'

import '../../assets/css/custombulma.css'
import 'font-awesome/css/font-awesome.min.css'

export class NewServiceRequest extends Component {

  constructor(props) {
    super(props)

    const configs = JSON.parse(localStorage.getItem('configs'))
    const clients = localStorage.getItem('user-clients').split(",")
    const clientsSAPOrder = configs["CLIENTES_ORDEM_SAP"].split(",")
    this.state = {
      client: {},
      technician: [1],
      technicianCode: null,
      resource: {},
      hourMeter: '',
      description: '',
      orderSAP: '',
      resourceSituation: 'OPERANDO',
      scheduleServiceDate: new Date(),
      stopTime: new Date(),
      redirectToServiceRequest: false,
      showSAPOrder: clients.every(id=> clientsSAPOrder.includes(id))
    }
  }

  componentDidMount() {

    this.serviceRequestService = new ServiceRequestService()
    this.resouceService = new ResourceService()
    const { id } = this.props.match.params

    this.resourceById(id)
  }

  resourceById = async (id) => {

    const response = await this.resouceService.get(id)

    const { client, technician, technicianCode, hourMeter, ...resource } = response

    this.setState({
      client,
      technician,
      technicianCode,
      resource,
      hourMeter: '',
      description: '',
      resourceSituation: 'OPERANDO',
      scheduleServiceDate: new Date(),
      stopTime: new Date()
    })
  }

  saveResourceService = async () => {

    const formatDate = (date) => moment(date).format("DD/MM/YYYY HH:mm")

    const idResource = this.state.resource.id
    const resourceCode = this.state.resource.code
    const idClient = this.state.client.id
    let {
      technicianCode,
      description,
      orderSAP,
      resourceSituation,
      scheduleServiceDate,
      stopTime,
      hourMeter
    } = this.state

    scheduleServiceDate = formatDate(scheduleServiceDate)
    stopTime = resourceSituation === 'PARADO' ? formatDate(stopTime) : null

    let serviceResquest = {
      idClient,
      description,
      orderSAP,
      idResource,
      resourceCode,
      hourMeter,
      resourceSituation,
      scheduleServiceDate,
      stopTime
    }

    try {
      await this.serviceRequestService.post(serviceResquest)
      this.setState({ redirectToServiceRequest: true })
      SweetAlert.confirmSucess('Seu chamado foi criado com sucesso!')
    } catch (e) {
      console.log(e)
      this.setState({ redirectToServiceRequest: true })
    }
  }

  handleChange = (event) => {

    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    if (name === 'hourMeter' && value < 0) value = value * -1

    this.setState({ [name]: value })
  }

  render() {

    const code = this.state.resource.code
    const resourceDescription = this.state.resource.description
    const {
      stopTime,
      scheduleServiceDate,
      description,
      orderSAP,
      hourMeter,
      redirectToServiceRequest,
      showSAPOrder
    } = this.state
    registerLocale('pt', pt)

    if (redirectToServiceRequest) {
      return <Redirect to={'/chamados'} />
    }

    return (
      <Fragment>
        <Navbar />
        <div style={{ paddingTop: 55, }}>
          <section className="section is-bold">
            <div className="field">
              <label className="label">Frota</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  value={`${code} - ${resourceDescription}`}
                  readOnly
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Descrição</label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Descrição do chamado"
                  name="description"
                  value={description}
                  minLength="10"
                  maxLength="500"
                  onChange={this.handleChange}
                >
                </textarea>
              </div>
            </div>

            <div className="columns">

              <div className="field column">
                <label className="label">Situação do equipamento:</label>
                <div className="control">
                  <div className="select">
                    <select
                      name='resourceSituation'
                      value={this.state.resourceSituation}
                      onChange={this.handleChange}
                    >
                      <option value="OPERANDO">Operando</option>
                      <option value="PARADO">Parado</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="field column">
                <label className="label">Horímetro: </label>
                <div className="control">
                  <input
                    min="0"
                    className="input"
                    type="number"
                    name="hourMeter"
                    value={hourMeter}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="field column">
                <label className="label" htmlFor="input-data-schedule">
                  Agendar para:
                </label>
                <DatePicker
                  minDate={new Date()}
                  className="control"
                  customInput={
                    <InputMask
                      mask="99/99/9999 99:99"
                      value={scheduleServiceDate} />
                  }
                  selected={scheduleServiceDate}
                  onChange={(date) => {
                    this.setState({
                      scheduleServiceDate: date
                    })
                  }}
                  showTimeSelect={true}
                  name="scheduleServiceDate"
                  timeFormat={"HH:mm"}
                  dateFormat="dd/MM/yyyy HH:mm"
                  locale="pt"
                />
              </div>

              {
                this.state.resourceSituation === 'PARADO' ? <div className="field column">
                  <label className="label" htmlFor="input-date-stop">
                    Data da parada:
                  </label>
                  <DatePicker
                    maxDate={new Date()}
                    className="control"
                    customInput={
                      <InputMask
                        mask="99/99/9999 99:99"
                        value={stopTime}
                      />
                    }
                    selected={stopTime}
                    onChange={(date) => {
                      this.setState({
                        stopTime: date
                      })
                    }}
                    showTimeSelect={true}
                    name="stopTime"
                    timeFormat={"HH:mm"}
                    dateFormat="dd/MM/yyyy HH:mm"
                    locale="pt"
                  />
                </div>
                  : null
              }

            </div>
            {showSAPOrder && <div className="field">
              <label className="label">Ordem SAP</label>
              <div className="control">
                <input
                  className="input"
                  name="orderSAP"
                  type="text"
                  value={orderSAP}
                  onChange={this.handleChange}
                />
              </div>
            </div>}
            {
              !description || description.length < 10 || !hourMeter || (showSAPOrder && !orderSAP) ?
                <div>
                  <button
                    className="button is-primary is-pulled-right is-normal"
                    title="Disabled button"
                    disabled
                  >
                    Abrir Chamado
                </button>
                </div>
                :
                <div className="field" >
                  <button
                    onClick={this.saveResourceService}
                    className="button is-primary is-pulled-right is-normal"
                    title="Disabled button"
                  >
                    Abrir Chamado
                </button>
                </div>
            }
          </section>
        </div>
      </Fragment>
    )
  }
}