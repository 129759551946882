export default {
  tabs: {},
  login: {
    email: 'Email',
    password: 'Senha',
    login: 'Login',
    go: 'Entrar',
    forgot_password: 'Esqueci minha senha',
    recover_password: 'Recuperar senha',
    type_your_email: 'Informe seu e-mail',
    warning: '',
  },
  resources: {
    not_found: 'Nenhuma frota cadastrada para esta usuário. Entre em contato com o administrador do sistema.',
  },
}