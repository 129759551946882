import React, { useState } from 'react'
import { Modal } from '../../components'
import StarratingComponent from 'react-star-rating-component'
import ServiceRequestFeedbackService from '../../services/servicerequestfeedback/servicerequestfeedback-service'
import ServiceRequestService from '../../services/servicerequest/servicerequest-service'

import {
    StarIcon,
    InfoIcon,
    Title,
    TextArea,
    RatingContainer,
    ButtonFeedback,
    StarColor,
    EmptyStarColor,
    Container,
    ButtonLink
} from './styles'


export const Feedback = (props) => {

    const { serviceRequest } = props
    const [isModalOpened, setModalOpened] = useState(false);
    const [ratingResponseTime, setratingResponseTime] = useState(3)
    const [ratingQuality, setratingQuality] = useState(3)
    const [comments, setComments] = useState('')
    const [disabledButton, setDisabledButton] = useState(false)
    const serviceRequestFeedbackService = new ServiceRequestFeedbackService()
    const serviceRequestService = new ServiceRequestService()

    const onResponseTimeClick = (nextValue) => setratingResponseTime(nextValue)
    const onQualityClick = (nextValue) => setratingQuality(nextValue)

    const saveFeedback = async () => {
        setDisabledButton(true)
        let serviceResquestFeedback = {
            comments,
            qualityScore: ratingQuality,
            velocityScore: ratingResponseTime,
            serviceRequestId: serviceRequest.id
        }
        await serviceRequestFeedbackService.post(serviceResquestFeedback)
        let statusChange = {
            id: serviceRequest.id,
            newStatus: 'FECHADO'
        }
        await serviceRequestService.changeStatus(statusChange)
        props.onAfterSave()
    }

    return (
        <Container>
            <Title>
                Como você avalia o chamado <ButtonLink href={null} onClick={() => setModalOpened(true)}>#{serviceRequest.id}<InfoIcon /></ButtonLink>?
            </Title>
            <RatingContainer>
                <h2><strong>Agilidade</strong> no atendimento</h2>
                <StarratingComponent
                    name="ratingResponseTime"
                    renderStarIcon={() => <StarIcon />}
                    starCount={5}
                    starColor={StarColor}
                    emptyStarColor={EmptyStarColor}
                    value={ratingResponseTime}
                    onStarClick={onResponseTimeClick}
                />
            </RatingContainer>
            <RatingContainer>
                <h2><strong>Qualidade</strong> do atendimento</h2>
                <StarratingComponent
                    name="ratingQuality"
                    renderStarIcon={() => <StarIcon />}
                    starCount={5}
                    starColor={StarColor}
                    emptyStarColor={EmptyStarColor}
                    value={ratingQuality}
                    onStarClick={onQualityClick}
                />
            </RatingContainer>
            <h2>Comentários</h2>
            <TextArea value={comments} onChange={(e) => setComments(e.target.value)}></TextArea>
            <ButtonFeedback disabled={disabledButton} onClick={() => saveFeedback()}>Enviar Avaliação</ButtonFeedback>
            <Modal isOpen={isModalOpened} enableDismiss={true} onAfterClose={()=>setModalOpened(false)}>             
                <ul>
                    <li>
                        <strong>Chamado: </strong>#{serviceRequest.id}
                    </li>
                    <li>
                        <strong>Descrição: </strong> {serviceRequest.description}
                    </li>
                    <li>
                        <strong>Frota: </strong> {serviceRequest.codeResource}
                    </li>
                    <li>
                        <strong>Data: </strong> {serviceRequest.createDate}
                    </li>
                </ul>
            </Modal>
        </Container>
    )
}
