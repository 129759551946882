import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: none;
  border-width: 1px;
  border-radius: 4px;

  padding: 10px;
  margin: 10px;
  background-color: none;
`

export const Title = styled.label`
  margin:10px 0 10px 0;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
`

export const Square = styled.div`
  width: 20px;
  height: 20px;
  margin: 0 5px 10px 0;
  border-style: solid;
  border-width: 1px;
  background-color: ${
    props => props.color ? props.color : 'none'
  };
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    overflow-x: hidden;
    min-width: 300px;
    max-width: 300px;
    max-height: 150px;
`

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
`

export const Description = styled.label`
  font-size: .8em;
  line-height: 1.5;
`