import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: none;
  border-width: 1px;
  border-radius: 4px;
  padding: 10px;
  margin: 10px;
  background-color: none;
`

export const Title = styled.label`
  margin:10px 0 10px 0;
  font-size: 1em;
  font-weight: bold;
  line-height: 1.5;
`

export const List = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

export const Badge = styled.div`
    display: flex;
    background-color: #4d8fbf;
    color: white;
    padding: 10px 10px;
    border-radius: 10px;
    font-size: 1rem; 
    font-weight: bold;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
    transition: transform 0.2s;
    margin: 10px;
`

export const Metric = styled.label`
  font-size: 1em;
  line-height: 1.5;
`