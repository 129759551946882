import React, { useState, Fragment } from 'react'

import {
    Title,
    Container,
    SubContainer,
    CalendarCheckIcon,
    Log,
    DateContainer,
    LogContainer,
    
} from './styles'


export const Logs = (props) => {

    const { serviceRequest } = props
    const { logs } = serviceRequest
    return (
        <>
            <Title>Detalhes do chamado #{serviceRequest.id}</Title>
            <Container>
                <ul>
                {logs && logs.map(log => {    
                    return (
                    <SubContainer>
                        <DateContainer> 
                            <CalendarCheckIcon></CalendarCheckIcon><strong>{log.createDate}</strong>
                        </DateContainer>
                        <LogContainer>
                            <Log>
                                {log.log}
                            </Log>
                        </LogContainer>
                    </SubContainer>
                    )
                })}
                </ul>
                <SubContainer>
                    <DateContainer> 
                        <CalendarCheckIcon></CalendarCheckIcon><strong>{serviceRequest.createDate}</strong>
                    </DateContainer>
                    <LogContainer>
                        <Log>
                            Chamado criado
                        </Log>
                    </LogContainer>
                </SubContainer>
            </Container>
        </>
    )
}
