import axios from "axios";
import { getToken } from "../../utils/auth";
import { Endpoints } from '../../config/constants'
import { SweetAlert } from '../../components/index'
import { isTokenExpired } from '../../utils/auth';

const CLASS_LOADER = '.loader'
const apiUrl = Endpoints.getApiUrl()


const httpClient = baseURL => axios.create({
  timeout: 30000,
  headers: {
    'Content-type': 'application/json'
  },
  baseURL
})

export class ChangePasswordService {
  constructor() {
    this.client = httpClient(apiUrl);

    this.client.interceptors.request.use(async config => {
      const token = getToken();
      isTokenExpired(token);
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    });

    this.showLoader(this.client)
  }

  showLoader(client) {
    client.interceptors.request.use(config => {
      clearTimeout(this.loaderTimeout)
      document.querySelector(CLASS_LOADER).style.visibility = 'visible';
      return config
    })

    client.interceptors.response.use(
      response => {
        this.loaderTimeout = setTimeout(() => {
          document.querySelector(CLASS_LOADER).style.visibility = 'hidden';
        }, 500)
        return response
      },
      error => {
        this.loaderTimeout = setTimeout(() => {
          document.querySelector(CLASS_LOADER).style.visibility = 'hidden';
        }, 500)
        return Promise.reject(error)
      }
    )
  }

  async post(obj) {
    return await this.client.post('/change-password', obj)
  }
}
