import React, { Fragment, useState, useEffect } from 'react'
import { Navbar, SelectField, DatePickerField } from '../../components'
import ReactGantt, { GanttRow } from '../../components/gantt';
import { Legend } from './legend/legend';
import { Summary } from './summary/summary';

import moment from 'moment'
import TimeRecordService from '../../services/timerecord/timerecord-service'
import {
  Label,
  RowContainer,
  ColumnContainer,
  FieldsContainer,
  Name
} from './styles'

export function Gantt() {


  const plannedColorPalette = [
    "#4dbfbf", 
    "#c2784d",
    "#4dbf6e",
    "#4d8fbf",
    "#bf4dab",
    "#8e4dbf",
    "#bfbb4d",
    "#4dbf8e",
    "#ffa34d"
  ];

  const unplannedColorPalette = [
    "#c24d5c",
    "#d25f6d",
    "#a23b49",
    "#e26f7d",
    "#82303a"
  ];
       
  
  const service = new TimeRecordService()
  const technicians = JSON.parse(localStorage.getItem('technicians'));
  const selecttableTechnicians = technicians.map(t => [t])

  selecttableTechnicians.unshift(
    technicians.filter(t => t.id === 290 ||  t.id === 305 || t.id === 749 
                      || t.id === 1298 || t.id === 318 || t.id === 633 || t.id === 1110
                      || t.id === 1112 || t.id === 1110 || t.id === 1318).map(t => {
                        return {
                          name: "OFICINA MATRIZ",
                          id: t.id
                        }
                      })
  );

  const [techniciansTasks, setTechniciansTasks] = useState([])
  const [steps, setSteps] = useState([])
  const [plannedSteps, setPlannedSteps] = useState([])
  const [plannedStepsColors, setPlannedStepsColors] = useState([])
  const [unplannedStepsColors, setUnplannedStepsColors] = useState([])
  const [stepsAgainstPlanned, setStepsAgainstPlanned] = useState([])
  const [stepsAgainstPlannedColors, setStepsAgainstPlannedColors] = useState([])
  const [templates, setTemplates] = useState()
  const [startDate, setStartDate] = useState(new Date())
  const [selectedTechnicians, setSelectedTechnicians] = useState([])
  const [totalPlannedHours, setTotalPlannedHours] = useState([])
  const [totalHours, setTotalHours] = useState([])
  const [percentageCompleted, setPercentageCompleted] = useState([])

  
  useEffect(() => {
    if(techniciansTasks) {
      document.querySelector('.loader').style.visibility = 'visible'
      const plannedSteps = []
      const plannedStepsColors = []
      const unplannedStepsColors = []
      const steps = []
      const templates = []
      const stepsAgainstPlannedColors = []
      const stepsAgainstPlanned = []


      const percentageCompleted = []
      const totalPlannedHours = []
      const totalHours = []

      let taskTotalTimes = {};

      let latestStatus = {};

      techniciansTasks.forEach(tasks => {
        const pauseGaps = []
        const taskPlannedSteps = []
        const taskSteps = []
        const completedTaks = []
      
        Object.keys(tasks).forEach(taskId => {
          const data = tasks[taskId];   
          
          if (data.plannedStart && data.plannedEnd) {
            const start = moment(data.plannedStart, 'DD-MM-YYYY HH:mm:ss')
            const end = moment(data.plannedEnd, 'DD-MM-YYYY HH:mm:ss');
  
            if((start.isSame(startDate, 'day') || end.isSame(startDate, 'day')) && !start.isSame(end)) {
              taskPlannedSteps.push({
                name: data.taskDescription,
                start: start.toDate(),
                end: end.toDate(),
                taskId: taskId
              })
            }
  
            const timeRecords = data.timeRecords
            if (timeRecords && timeRecords.length > 0) {
              for (let i = 0; i < timeRecords.length; i+=2) {
                const previousTimeRecord = timeRecords[i-1];
                const currentTimeRecord = timeRecords[i];
                const nextTimeRecord = timeRecords[i+1];

                if (currentTimeRecord.status === 'D' || nextTimeRecord && nextTimeRecord.status === 'D') {
                  completedTaks.push(taskId)
                }
  
                if (previousTimeRecord && previousTimeRecord.status === 'P') {
                  const pauseStart =  moment(previousTimeRecord.date, 'DD-MM-YYYY HH:mm:ss')
                  const pauseEnd =  moment(currentTimeRecord.date, 'DD-MM-YYYY HH:mm:ss')
  
                  const diffInMinutes = pauseEnd.diff(pauseStart, 'minutes');
                  if (diffInMinutes > 1) {
                    pauseGaps.push({
                      name: 'Pausado',
                      start: pauseStart.toDate(),
                      end:  pauseEnd.toDate(),
                      taskId: taskId
                    })
                  }
                }

                const start = moment(currentTimeRecord.date, 'DD-MM-YYYY HH:mm:ss').toDate()
                const end = nextTimeRecord ? moment(nextTimeRecord.date, 'DD-MM-YYYY HH:mm:ss').toDate() : new Date()

                const diff = moment(end, 'DD-MM-YYYY HH:mm:ss').diff(moment(start, 'DD-MM-YYYY HH:mm:ss'), 'seconds')

                if (taskTotalTimes.hasOwnProperty(taskId)) {
                  taskTotalTimes[taskId] += diff;
                } else {
                  taskTotalTimes[taskId] = diff;
                }

                const status = nextTimeRecord ? nextTimeRecord.status : currentTimeRecord.status

                latestStatus[taskId] = status;

                taskSteps.push({
                  name: data.taskDescription,
                  start: start,
                  end: end,
                  taskId: taskId,
                  plannedStart: data.plannedStart,
                  plannedEnd: data.plannedEnd,
                  status: status
                })
                
              }
            }     
          }
        })


          const updatedSteps = mergePauseGaps(taskSteps, pauseGaps);
          const sortedUpdatedSteps = sortSteps(updatedSteps)

          
          const taskStepsAgainstPlanned = [...sortedUpdatedSteps]

          if (taskStepsAgainstPlanned.length > 0) {
            const last = taskStepsAgainstPlanned[taskStepsAgainstPlanned.length-1]
            if (last.status !== 'D') {
              const diffTaskTime = taskTotalTimes[last.taskId]
              const plannedStart =  moment(last.plannedStart, 'DD-MM-YYYY HH:mm:ss')
              const plannedEnd =  moment(last.plannedEnd, 'DD-MM-YYYY HH:mm:ss')
              const diffPlannedTime = plannedEnd.diff(plannedStart, 'seconds');

              console.log(diffPlannedTime)
              console.log(moment(last.end).add(diffPlannedTime, 'seconds').toDate())
              
              if(diffTaskTime < diffPlannedTime) {
                console.log('entrei')
                taskStepsAgainstPlanned.push({
                  name: last.name,
                  start: moment(last.end).add(1  , 'seconds').toDate(),
                  end: moment(last.end).add(diffPlannedTime - diffTaskTime, 'seconds').toDate(),
                  taskId: last.taskId,
                  plannedStart: last.plannedStart,
                  plannedEnd: last.plannedEnd,
                  status: 'N'
                })
              }
  
            }
          }

          console.log(taskStepsAgainstPlanned)
       

          const taskStepsAgainstPlannedColors =  taskStepsAgainstPlanned.map(step => {
            if (step.name === 'Pausado') {
              return {
                name: step.name,
                color: '#ededed',
                taskId: step.taskId
              }
            }

            if (step.status === 'N') {
              return {
                name: step.name,
                color: '#b7d2e5',
                taskId: step.taskId
              }
            }
      
            const diffTaskTime = taskTotalTimes[step.taskId]

            const plannedStart =  moment(step.plannedStart, 'DD-MM-YYYY HH:mm:ss')
            const plannedEnd =  moment(step.plannedEnd, 'DD-MM-YYYY HH:mm:ss')
      
            const diffPlannedTime = plannedEnd.diff(plannedStart, 'seconds');
            
            if (diffTaskTime < diffPlannedTime || diffTaskTime === diffPlannedTime) {
              return {
                  name: step.name,
                  color: step.status === 'P' && !completedTaks.includes(step.taskId) && latestStatus[step.taskId] === 'P' ? "#e59900" : "#4d8fbf",
                  taskId: step.taskId
                }
            } else {    
              return {
                name: step.name,
                color: "#BF4D4D",
                taskId: step.taskId
              }
            } 
          }).flatMap(item => [item, item])

          console.log(taskStepsAgainstPlannedColors)
      
          const sortedTaskPlannedSteps = fillGaps(sortSteps(taskPlannedSteps))

          const taskPlannedStepsColors = sortedTaskPlannedSteps.map((step, index) => {
                                let color = '#ededed';
                                if (step.name !== 'Pausado') {
                                  color = findColorByIndex(index, true)
                                }

                                return {
                                  name: step.name,
                                  color: color,
                                  taskId: step.taskId
                                }
                              }).flatMap(item => [item, item])

          const taskUnplannedStepsColors = []                    
          const stepsColors =  sortedUpdatedSteps.map((step, index) => {
                                let color = '#ededed';
                                if (step.name !== 'Pausado') {
                                  const plannedStepColor = taskPlannedStepsColors.find(plannedStep => plannedStep.taskId === step.taskId)
                                  if (plannedStepColor) {
                                    color = plannedStepColor.color
                                  } else {
                                    color = findColorByIndex(index, false)
                                    if(!taskUnplannedStepsColors.some(t => t.taskId == step.taskId)) {
                                      taskUnplannedStepsColors.push({
                                        name: step.name,
                                        color: color,
                                        taskId: step.taskId
                                      })
                                    }
                                  }
                                }
    
                                return {
                                  name: step.name,
                                  color: color
                                }
    
                              }).flatMap(item => [item, item])
    
          steps.push(sortedUpdatedSteps)                            
          plannedSteps.push(sortedTaskPlannedSteps)
          plannedStepsColors.push(taskPlannedStepsColors)

          stepsAgainstPlanned.push(sortSteps(taskStepsAgainstPlanned))
          stepsAgainstPlannedColors.push(taskStepsAgainstPlannedColors)

          percentageCompleted.push(calculatePercentageCompleted(tasks));
          totalPlannedHours.push(calculateTotalPlannedHours(taskPlannedSteps))
          totalHours.push(calculateTotalHours(sortedUpdatedSteps))
          unplannedStepsColors.push(taskUnplannedStepsColors)

          templates.push({
            previsto: {
              title: 'Planejado',
              steps: taskPlannedStepsColors
            },
            executado: {
              title: 'Executado',
              steps: stepsColors
            },
            ExecutadoPlanejado: {
              title: 'ExecutadoPlanejado',
              steps: taskStepsAgainstPlannedColors
            },
          })
        })

      setPlannedSteps(plannedSteps)
      setPlannedStepsColors(plannedStepsColors)
      setStepsAgainstPlanned(stepsAgainstPlanned)
      setStepsAgainstPlannedColors(stepsAgainstPlannedColors)
      setSteps(steps)
      setTemplates(templates)
      setPercentageCompleted(percentageCompleted)
      setTotalPlannedHours(totalPlannedHours)
      setTotalHours(totalHours)
      setUnplannedStepsColors(unplannedStepsColors)
      document.querySelector('.loader').style.visibility = 'hidden'
    }
  }, [techniciansTasks])

  function fillGaps(steps) {
    for (let i = 1; i < steps.length; i+=2) {

      const previousStep = steps[i-1];
      const currentStep = steps[i];

      const start =  moment(currentStep.start, 'DD-MM-YYYY HH:mm:ss')
      const end =  moment(previousStep.end, 'DD-MM-YYYY HH:mm:ss')

      const diffInMinutes = start.diff(end, 'minutes');
      if (diffInMinutes > 1) {
        steps.push({
          name: 'Pausado',
          start: end.toDate(),
          end:  start.toDate()
        })
      }
    }
    return sortSteps(steps);
  }


  function calculatePercentageCompleted(tasks) {
    let totalTasks = 0;
    let tasksWithDStatus = 0;

    for (const taskId in tasks) {
        const task = tasks[taskId];
        totalTasks++;

        if (task.timeRecords && task.timeRecords.length > 0) {
            for (const record of task.timeRecords) {
                if (record.status === 'D') {
                    tasksWithDStatus++;
                    break;
                }
            }
        }
    }

    if (totalTasks === 0) {
      return '0.00';
    }

    const percentage = (tasksWithDStatus / totalTasks) * 100;

    return percentage.toFixed(2);
}

  function isOverlapping(start1, end1, start2, end2) {
    return (start1 < end2 && end1 > start2);
  }

  function mergePauseGaps(steps, pauseGaps) {
    let result = [...steps];
  
    pauseGaps.forEach(pause => {
      let overlap = result.some(step => isOverlapping(step.start, step.end, pause.start, pause.end));
      if (!overlap) {
        result.push(pause);
      }
    });
  
    return result;
  }


  const sortSteps = (steps) => {
    return steps ? steps.sort((a,b)=>a.start.getTime()-b.start.getTime()) : []
  }

  function calculateTotalHours(steps) {
    let totalHours = 0;
  
    steps.forEach(step => {
      if (step.name !== 'Pausado') {
        const startTime = new Date(step.start);
        const endTime = new Date(step.end);
        const duration = (endTime - startTime) / (1000 * 60 * 60);
        totalHours += duration;
      }
    });
  
    return totalHours.toFixed(2);
  }

  function calculateTotalPlannedHours(steps) {
    const START_HOUR = 18
    const END_HOUR = 8;
  
    let totalHours = 0;
  
    steps.forEach(step => {
      if (step.name !== 'Pausado') {
        const startTime = new Date(step.start);
        const endTime = new Date(step.end);
  
        let currentStartTime = new Date(startTime);
  
        while (currentStartTime < endTime) {
          let currentEndTime = new Date(currentStartTime);
  
          if (currentStartTime.getHours() >= START_HOUR) {
            currentEndTime.setHours(24, 0, 0, 0);
          } else if (currentStartTime.getHours() < END_HOUR) {
            currentEndTime.setHours(END_HOUR, 0, 0, 0);
          } else {
            currentEndTime.setHours(START_HOUR, 0, 0, 0);
          }
  
          currentEndTime = new Date(Math.min(currentEndTime, endTime));
  
          if (currentStartTime.getHours() >= END_HOUR && currentStartTime.getHours() < START_HOUR) {
            const duration = (currentEndTime - currentStartTime) / (1000 * 60 * 60);
            totalHours += duration;
          }
  
          currentStartTime = new Date(currentEndTime);
        }
      }
    });
  
    return totalHours.toFixed(2);
  }

  useEffect(() => {    
    const listByUserAndDate = async () => {
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");

      setTechniciansTasks(await Promise.all(selectedTechnicians.value.map(id => {
        return service.listByUserAndDate(id, formattedStartDate);
      })));
    }

    if(selectedTechnicians.value && startDate) {
      listByUserAndDate()
    }
  }, [selectedTechnicians, startDate])
 
  const findColorByIndex = (index, isPlanned) => {

    const colorPalette = isPlanned ? plannedColorPalette : unplannedColorPalette
    
    const effectiveIndex = index % colorPalette.length;

    return colorPalette[effectiveIndex];
  }
  

  const getTechnicianName = (id) => {
    const technician = technicians.find(t => t.id === id)
    return technician ? technician.name : id
  }

  const renderLegend = (index) => {
    let plannedTasks = []
    if (plannedStepsColors && plannedStepsColors[index]) {

      plannedTasks = plannedStepsColors[index].filter((_, index) => index % 2 === 0)
    }

    let unplannedTasks = []
    if (unplannedStepsColors && unplannedStepsColors[index]) {
      unplannedTasks = unplannedStepsColors[index]
    }


    return <Legend plannedItems={plannedTasks} unplannedItems={unplannedTasks}/>
  }

  const renderGantt = () => {
    if(techniciansTasks && techniciansTasks.length > 0 && templates && Object.keys(templates).length > 0) {
      const leftBound = new Date(startDate)
      leftBound.setHours(8,0,0)

      const rightBound = new Date(startDate)
      rightBound.setHours(23,0,0)


      return techniciansTasks.map((_, index) => {
            return <>
            <Name>{getTechnicianName(selectedTechnicians.value[index])}</Name>
            <RowContainer>
              {renderLegend(index)}
              <ColumnContainer>
                <ReactGantt
                  dateFormat='DD-MM-YYYY HH:mm'
                  templates={templates[index]}
                  leftBound={leftBound}
                  rightBound={rightBound}
                >
                  {plannedSteps && plannedSteps[index] && <GanttRow
                    title='Previsto'
                    description='Previsto'
                    templateName='previsto'
                    barStyle={{
                      height: '50px',
                      marginBottom: '10px'
                    }}
                    steps={plannedSteps[index].map(step => [step.start, step.end]).flat()}
                    >
                  </GanttRow>}

                  
                  {steps && steps[index] && <GanttRow
                    title='Executado'
                    description='Executado'
                    templateName='executado'
                    barStyle={{
                      height: '50px'  ,
                      marginBottom: '10px'
                    }}
                    steps={steps[index].map(step => [step.start, step.end]).flat()}
                    >
                  </GanttRow>}

                  {stepsAgainstPlanned && stepsAgainstPlanned[index] && <GanttRow
                    title='Executado x Planejado'
                    description='Executado x Planejado'
                    templateName='ExecutadoPlanejado'
                    barStyle={{
                      height: '50px'  ,
                      marginBottom: '10px'
                    }}
                    steps={stepsAgainstPlanned[index].map(step => [step.start, step.end]).flat()}
                    >
                  </GanttRow>}
      
                </ReactGantt>
                <Summary totalHours={totalHours[index]} totalPlannedHours={totalPlannedHours[index]} percentageCompleted={percentageCompleted[index]}/>
              </ColumnContainer>
            </RowContainer>
            <hr/>
            </>
      })
    } else {
      return <Label>Sem Registros</Label>
    }

  }

  return (
    <Fragment>
      <Navbar />
      <FieldsContainer>
        <Label htmlFor="technician-select">
          Técnico:
        </Label>
        <SelectField
          id="technician-select"
          value={selectedTechnicians}
          onChange={setSelectedTechnicians}
          options={selecttableTechnicians.map(t => {
              return {
                value: t.map(t => t.id),
                label: t[0].name
              }
          })}
        />
        <Label htmlFor="start-date">
          Data Inicial:
          </Label>
          <DatePickerField
            selected={startDate}
            onChange={setStartDate}
            id="start-date"
          />
      </FieldsContainer>
      {renderGantt()}
    </Fragment>
  )
}