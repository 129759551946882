import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 400px;
  justify-content: space-around;
  width: 100%;
  max-width: 350px;
`;

export const InputText = styled.input`
  font-size: 16px;
  background: #E8F0FE;
  border: none;
  border-bottom: 2px solid #000000;
  width: 100%;
`;

export const Button = styled.button`
  color: #FFFF;
  background: transparent;
  border: ${
    props => props.disabled ? '2px solid #999999' : '2px solid #006838'
  };
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 1em;
  padding: 0.25em 1em;
  transition: 0.5s all ease-out;
  width: 100%;
  max-width: 350px;
  background-color: ${ props => props.disabled ? '#cccccc' : '#006838'};
`;

export const Text = styled.p`
  color: #006838;
  font-size: 30px;
  font-weight: bold;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
`;

export const SucessMessage = styled.p`
  color: #006838;  
  font-size: 14px;
  text-align: center;
`;

export const Link = styled.a`
  color: #006838;
`;
