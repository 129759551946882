import React from 'react'
import {
  Container,
  Title,
  List,
  Badge,
  Metric
} from './styles'

export function Summary(props) {

   return (
    <> 
      <Container>
        <List>
          <Badge><Metric>{`Percentual Completo = ${props.percentageCompleted ? props.percentageCompleted : 0}%`}</Metric></Badge>
          <Badge><Metric>{`Total Planejado (hs) = ${props.totalPlannedHours ? props.totalPlannedHours : 0}`}</Metric></Badge>
          <Badge><Metric>{`Total Executado (hs) = ${props.totalHours ? props.totalHours : 0}`}</Metric></Badge>
        </List>
      </Container>
    </>
  )
}