import React, { Component, Fragment } from 'react'
import { Navbar } from '../../components'
import HistoryService from '../../services/history/history-service'
import 'react-accessible-accordion/dist/fancy-example.css';
import { logout } from '../../utils/auth'
import { Redirect } from 'react-router-dom'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import '../../assets/css/custombulma.css'
import 'font-awesome/css/font-awesome.min.css'
import * as S from './styles'

export class History extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
        user: {},
        list: []
    };
  }

  componentDidMount() {
    this.historyService = new HistoryService()
    document.querySelector('.loader').style.visibility = 'hidden'
    this.loadResource()

    const userData = localStorage.getItem('user-data')
    if(!userData) {
      logout()
      return <Redirect to="/login" />
    }
    const user = JSON.parse(userData)
    this.setState({user: user})
  }   

  loadResource = async (page = 0) => {

    const { id } = this.props.match.params
    const response = await this.historyService.list(id)

    if(!response) return

      this.setState({
        list: [...this.state.list, ...response]
    })  
  }


  render() {
    const { list, user } = this.state
    return (
      <Fragment>
        <Navbar />
        <div style={{ paddingTop: 55, }}>
            {!list[0] ? 
            <div className="container is-fullhd">
                <div className="notification">
                    Nenhum Histórico Encontrado...
                </div>
            </div>
            :  
            <Fragment>
                <section class="hero">
                    <div class="hero-body">
                        <h1 class="title">
                            Frota {this.props.match.params.code}
                        </h1>    
                        <h2 class="subtitle">
                           Histórico
                        </h2>    
                    </div>
                </section>

                {list.map(operation => {
                    return <Accordion allowZeroExpanded>
                        <AccordionItem >
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                <b>{operation.scheduledDate}</b> - {operation.title}
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <h2 class='subtitle'>{operation.description}</h2>

                                <h2 class='subtitle'>Tarefas:</h2>   
                                <div class='list'> 
                                    <ul>
                                        {operation.tasks.map((task, index) => {
                                            return (
                                                <div class='list-item'> 
                                                    <li>
                                                        <S.Task>
                                                            <S.Label># {index +1} - {task.description}</S.Label> 
                                                            <S.List>
                                                                <S.Item>
                                                                    <S.Label>Status:</S.Label> 
                                                                    <S.Description>{task.taskStatus}</S.Description>
                                                                </S.Item>
                                                                {task.hourMeter > 0 && <S.Item>
                                                                        <S.Label>Horímetro:</S.Label>
                                                                        <S.Description>{task.hourMeter}</S.Description>
                                                                    </S.Item>
                                                                }
                                                                {task.report && user.role != 'ROLE_CLIENTE' && <S.Item>
                                                                        <S.Label>Info. adicional:</S.Label> 
                                                                        <S.Description>{task.report}</S.Description>
                                                                    </S.Item>
                                                                }
                                                            </S.List>
                                                        </S.Task>  
                                                    </li>
                                                </div>  
                                            ) 
                                        })}
                                    </ul>
                                </div>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>
                })}
            </Fragment>
            }
            </div>
      </Fragment>
    )
  }
}