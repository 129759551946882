import axios from "axios";
import { getToken, logout, isTokenExpired } from "../../utils/auth";
import { Endpoints } from '../../config/constants'
import { SweetAlert } from '../../components/index'

const CLASS_LOADER = '.loader'
const apiUrl = Endpoints.getApiUrl()

const httpClient = baseURL => axios.create({
  timeout: 30000,
  baseURL
})

export default class BaseService {
  constructor() {
    this.client = httpClient(apiUrl);

    this.client.interceptors.request.use(async config => {
      const isAuthRequest = config.url.split("/")[0] !== "public"

      if (isAuthRequest) {
        const token = await getToken()

        if (isTokenExpired(token)) {
          config.headers.Authorization = token
        }
      }
      return config
    });

    this.showLoader(this.client)
  }

  showLoader(client) {
    client.interceptors.request.use(config => {
      clearTimeout(this.loaderTimeout)
      document.querySelector(CLASS_LOADER).style.visibility = 'visible';
      return config
    })

    client.interceptors.response.use(
      response => {
        this.loaderTimeout = setTimeout(() => {
          document.querySelector(CLASS_LOADER).style.visibility = 'hidden';
        }, 500)
        return response
      },
      error => {
        this.loaderTimeout = setTimeout(() => {
          document.querySelector(CLASS_LOADER).style.visibility = 'hidden';
        }, 500)
        return Promise.reject(error)
      }
    )
  }

  handleError(error) {
    const response = error.response;
    if (response) {
        SweetAlert.connectionFailed(response.message)
        console.log(response)
    } else if (error.request) {
      SweetAlert.connectionFailed()
    }
  }

  async get(url) {
    try {

      const result = await this.client.get(url)
      return result.data

    } catch (error) {
      this.handleError(error)
    }
  }

  async getPDF(url) {
    try {

      const result = await this.client.get(url,  {responseType: 'blob'})
      return result.data

    } catch (error) {
      this.handleError(error)
    }
  }


  async postWithMultipartParams(url, formData) {
    try {

      const result = await this.client.post(url, formData)
      return result.data

    } catch (error) {
      this.handleError(error)
    }
  }

  async post(url, obj) {
    try {

      const result = await this.client.post(url, obj)
      return result.data

    } catch (error) {
      this.handleError(error)
    }
  }

  async put(url, obj) {
    try {

      const result = await this.client.put(url, obj)
      return result.data

    } catch (error) {
      this.handleError(error)
    }
  }

  async delete(url, obj) {
    try {

      const result = await this.client.delete(url, obj)
      return result.data

    } catch (error) {
      this.handleError(error)
    }
  }
}
