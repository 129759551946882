import React from 'react'
import DatePicker from 'react-datepicker'
import { registerLocale } from  "react-datepicker";
import InputMask from 'react-input-mask'
import './datePicker.css';

import ptBR from 'date-fns/locale/pt-BR';
registerLocale('ptBR', ptBR)

export function DatePickerField(props) {
    return  <DatePicker
                customInput={
                    <InputMask
                    mask="99/99/9999"
                    value={props.selected}
                    className='date-input' />
                }
                selected={props.selected}
                onChange={props.onChange}
                showTimeSelect={false}
                id={props.id}
                dateFormat="dd/MM/yyyy"
                locale="ptBR"
            />
}