import BaseService from '../base/base-service'
import { Endpoints } from '../../config/constants' 

const user = Endpoints.USER
export default class UserService extends BaseService {
    async get(id){
        return super.get(`${user}/${id}`)
    }

    async listTechnicians(){
        return super.get(`${user}/list-technicians`)
    }
}