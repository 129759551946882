import ENV from '../environments'

let CURRENT_ENV;
var APP_ENV = process.env.REACT_APP_ENV;

console.info(`Current env is ${APP_ENV}.`)

switch (APP_ENV) {
  case 'dev':
    CURRENT_ENV = ENV.LOCAL;
    break;
  case 'stage':
    CURRENT_ENV = ENV.STAGE;
    break;
  case 'prod':
    CURRENT_ENV = ENV.PRD;
    break;
  default:
    CURRENT_ENV = ENV.STAGE;
    console.warn(`APP_ENV ${APP_ENV} não reconhecido. Definindo ambiente como Stage.`); 
    break;
}

export class Environments {
  static getCurrent() {
    return CURRENT_ENV
  }

  static setDev() {
    CURRENT_ENV = ENV.LOCAL
  }

  static setStage() {
    CURRENT_ENV = ENV.STAGE
  }

  static setPrd() {
    CURRENT_ENV = ENV.PRD
  }
}