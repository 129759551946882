const LOCAL = {
  NAME: 'DEV',
  API_URL: 'http://localhost:8080/carmak-api-v2',
};

const STAGE = {
  NAME: 'STAGE',
  API_URL: 'https://api-stage.maintenance-manager.com.br/carmak-api-v2',
};

const PRD = {
  NAME: 'PRD',
  API_URL: 'https://api-carmak.maintenance-manager.com.br/carmak-api-v2',
};

export default {
  LOCAL,
  STAGE,
  PRD,
}