import React, { Fragment, Component } from 'react';
import './../../assets/css/custombulma.css';
import { Navbar, Modal, ChangePasswordContent } from '../../components'
import { ResourcesPanel } from './component/resources-panel'
import ResourceService from '../../services/resource/resource-service'
import strings from '../../config/locales/br'
import { logout } from '../../utils/auth'
import { Redirect } from 'react-router-dom'

export class Resources extends Component {

  constructor(props) {
    super(props)

    this.state = {
      user: {},
      list: [],
      page: 0,
      objectInfo: {},
      scrolling: true,
      showModal: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
    this.resouceService = new ResourceService()
    this.loadResource()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  loadResource = async (page = 0) => {
    const userData = localStorage.getItem('user-data')
    if(!userData) {
      logout()
      return <Redirect to="/login" />
    }

    const user = JSON.parse(userData)

    const response = await this.resouceService.list(page)

    if(!response) return

    const { content, ...objectInfo } = response

    this.setState({
      user,
      list: [...this.state.list, ...content],
      objectInfo,
      page,
      scrolling: true
    })
  }

  loadMore = () => {
    const { page } = this.state

    this.loadResource(page)
  }

  loadBySearchTerm = async (searchTerm) => {
    const response = await this.resouceService.list(null, searchTerm)

    if (!response) return

    const { content, ...objectInfo } = response

    this.setState({
      list: [...content],
      objectInfo,
      scrolling: true
    })
  }

  onScroll = () => {

    const { page, scrolling } = this.state
    const { totalPages } = this.state.objectInfo

    if (
      (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100) &&
      (page < totalPages - 1) && scrolling
    ) {
      this.setState({ page: page + 1, scrolling: false }, () => this.loadMore())
    }
  }

  changePassword = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  render() {
    const { list, showModal } = this.state

    return (
      <Fragment>

        <Modal isOpen={showModal} enableDismiss onAfterClose={this.changePassword}>
          <ChangePasswordContent />
        </Modal>

        <Navbar enableSearch loadBySearchTerm={this.loadBySearchTerm} changePassword={this.changePassword} />

        <div style={{ paddingTop: 55, }}>
          <div className="layout-default">
            <section className="is-fullheight is-medium is-bold">
              {!list[0] ? <div className="container is-fullhd">
                <div className="notification">
                  {strings.resources.not_found}
                </div>
              </div> :
                <ResourcesPanel list={list} />
              }
            </section>
          </div>
        </div>
      </Fragment>
    )
  }
}