import React, { Component, Fragment } from 'react'
import { Navbar } from '../../components'
import DatePicker from 'react-datepicker'
import ServiceRequestService from '../../services/servicerequest/servicerequest-service'
import 'react-accessible-accordion/dist/fancy-example.css';
import { logout } from '../../utils/auth'
import { Redirect } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css"
import { PieChart } from 'react-minimal-pie-chart';
import DataTable, { createTheme } from 'react-data-table-component';
import InputMask from 'react-input-mask'
import Collapsible from 'react-collapsible';

import '../../assets/css/custombulma.css'
import 'font-awesome/css/font-awesome.min.css'
import * as S from './styles'

window.setTimeout( function() {
  window.location.reload();
}, 300000);

const red = '#BB282E';

const columns = [
  {
    name: 'Data de Criação',
    selector: row => row.createDate,
    width: '150px'
  },
  {
    name: 'ID Chamado',
    selector: row => row.id,
    width: '100px'
  },
  {
      name: 'Descrição',
      selector: row => row.description,
      minWidth: '400px'
  },
  {
      name: 'Status',
      selector: row => row.status,
  },
  {
    name: 'Cliente',
    selector: row => row.resource.client.businessName,
  },
  {
    name: 'Loja',
    selector: row => row.resource.client.store,
    width: '30px'
  },
  {
    name: 'Frota',
    selector: row => row.resource.code,
  },
  {
      name: 'Situação Frota',
      selector: row => row.resourceSituation,
  },
  {
    name: 'Ordem SAP',
    selector: row => row.orderSAP,
  }
];

const idleResourceColumns = [
  {
    name: 'Código',
    selector: row => row.code,
    style: {color: red},
  },
  {
    name: 'Cliente',
    selector: row => row.clientName,
    style: {color: red},
  },
  {
    name: 'Descrição',
    selector: row => row.description,
    style: {color: red},
  },
  {
    name: 'Chamado',
    selector: row => row.serviceRequestId,
    style: {color: red},
  },
  {
    name: 'Data Parada',
    selector: row => row.stopTime,
    style: {color: red},
  },
  {
    name: 'Horas Parada',
    selector: row => row.hoursIdle,
    style: {color: 'white', backgroundColor: red},
  }
];

const paginationComponentOptions = {
  noRowsPerPage: true,
  rangeSeparatorText: 'de'
};

const conditionalRowStyles = [
 	{
 	when: row => row.status === 'CONCLUIDO',
 		style: {
 			backgroundColor: '#3FC380',
 			color: 'white',
 			'&:hover': {
 				cursor: 'pointer',
 			},
 		},
 	},
 	{
  when: row => row.status === 'EM_ATENDIMENTO' || row.status === 'VISUALIZADO',
 		style: {
 			backgroundColor: '#0c65a1',
 			color: 'white',
 			'&:hover': {
 				cursor: 'pointer',
 			},
 		},
 	},
 	{
    when: row => row.status === 'ABERTO',
 		style: {
 			backgroundColor: '#ff3860',
 			color: 'white',
 			'&:hover': {
 				cursor: 'not-allowed',
 			},
 		},
 	},
 ];


export class Dashboard extends Component {

  constructor(props) {
    super(props)
    let startDate = new Date()
    startDate.setMonth(startDate.getMonth() - 3)

    this.state = {
        user: {},
        statsList: [],
        selectedStats: {},
        serviceResquests: [],
        idleResources: [],
        totalRows: 0,
        idleResourceTotalRows: 0,
        startDate: startDate,
        endDate: new Date(),
        statusFilter: null,
        selectedStatsIndex: 0
    };
  }

  

  componentDidMount() {
    this.serviceRequestService = new ServiceRequestService()
    document.querySelector('.loader').style.visibility = 'hidden'


    const userData = localStorage.getItem('user-data')
    if(!userData) {
      logout()
      return <Redirect to="/login" />
    }

    const user = JSON.parse(userData)
    this.loadStats(user)
    
    this.setState({
      user: user
    })
    
  }   

  loadStats = async (user) => {
    const { startDate, endDate } = this.state
    
    const formattedStartDate =  startDate.toISOString().slice(0, 10);
    const formattedEndDate =  endDate.toISOString().slice(0, 10);

    const response = await this.serviceRequestService.getStats(user.clients.ids, formattedStartDate, formattedEndDate)

    if(!response) return
      const allClients = [...response].reduce((result, current) => {
      
        for(let key in current) {
          if (current.hasOwnProperty(key)) {
              if (key === 'clientName') {
                result[key] = 'TODOS CLIENTES'
              } else if (key === 'clientId') {
                result[key] =  result[key]+current[key]+","
              } else {
                result[key] = result[key]+current[key];
              }
              
            }
        }
        return result;
      }, {
        clientId: '',
        clientName: 'TODOS CLIENTES',
        doneCount: 0,
        idleResources: 0,
        inProgressCount: 0,
        openCount: 0,
        total: 0,
      })

      console.log(allClients)
      this.setState({
        statsList: [ allClients, ...response],
        selectedStats: allClients,
        selectedStatsIndex: 0
    }, () => this.loadData(user))  
  }

  loadData = async (user) => {
    const { startDate, endDate, statsList } = this.state
    
    const formattedStartDate =  startDate.toISOString().slice(0, 10);
    const formattedEndDate =  endDate.toISOString().slice(0, 10);

    const firstClient = statsList[0].clientId;
    const serviceRequestsResponse = await this.serviceRequestService.listByClient(0, firstClient, null, formattedStartDate, formattedEndDate)
    const idleResourcesResponse = await this.serviceRequestService.listIdleResourcesByClientId(0, firstClient, formattedStartDate, formattedEndDate)

    let serviceRequestsResponseContent = []
    let idleResourcesResponseContent = []
    let serviceRequestsTotalElements = 0
    let idleResourcesTotalElements = 0
    if(serviceRequestsResponse) {
      serviceRequestsResponseContent = serviceRequestsResponse.content
      serviceRequestsTotalElements = serviceRequestsResponse.totalElements
    } 
    if(idleResourcesResponse) {
      idleResourcesResponseContent = idleResourcesResponse.content
      idleResourcesTotalElements = idleResourcesResponse.totalElements
    }
    this.setState({
        serviceResquests: [ ...serviceRequestsResponseContent],
        idleResources: [ ...idleResourcesResponseContent],
        totalRows: serviceRequestsTotalElements,
        idleResourceTotalRows: idleResourcesTotalElements
    })  
  }

  handleClientChange = async (event) => {
    const target = event.target
    let value = target.value

    const { startDate, endDate } = this.state

    const formattedStartDate =  startDate.toISOString().slice(0, 10);
    const formattedEndDate =  endDate.toISOString().slice(0, 10);

    const { statsList, statusFilter } = this.state
    const clientId = statsList[value].clientId
    const serviceRequestsResponse = await this.serviceRequestService.listByClient(0, clientId, statusFilter, formattedStartDate, formattedEndDate)
    const idleResourcesResponse = await this.serviceRequestService.listIdleResourcesByClientId(0, clientId, formattedStartDate, formattedEndDate)

    this.setState({ selectedStats: statsList[value],
      serviceResquests: [],
      totalRows: 0,
      idleResources: [],
      idleResourceTotalRows: 0,
      selectedStatsIndex: value })

      
    if (serviceRequestsResponse) {
      this.setState({ 
        serviceResquests: [...serviceRequestsResponse.content],
        totalRows: serviceRequestsResponse.totalElements })
    }

    
    if (idleResourcesResponse) {
      this.setState({ 
        idleResources: [...idleResourcesResponse.content],
        idleResourceTotalRows: idleResourcesResponse.totalElements})
    }
      
  }

  filterByStatus = status  => {
    this.setState({ statusFilter: status}, () => this.handlePageChange(0));
	};

  handlePageChange = async page  => {
    
    const { startDate, endDate } = this.state

    const formattedStartDate =  startDate.toISOString().slice(0, 10);
    const formattedEndDate =  endDate.toISOString().slice(0, 10);
    const { selectedStats, statusFilter } = this.state
    const response = await this.serviceRequestService.listByClient(page-1, selectedStats.clientId, statusFilter, formattedStartDate, formattedEndDate)
    this.setState({ serviceResquests: [...response.content],  totalRows: response.totalElements})
	};

  render() {
    const { user, startDate, endDate, statsList, selectedStats, serviceResquests, totalRows, idleResources, idleResourceTotalRows } = this.state
    return (
      <Fragment>
      <Navbar />
      <div style={{ paddingTop: 80, }}>

        <S.Header>
          <S.HeaderContainer>
            <S.Title>Dashboard de Chamados</S.Title>
            <Collapsible trigger="Mostrar Filtros +" triggerStyle={{paddingLeft: '35px', cursor:'pointer', color:'#3FC380'}}>
            <div className="field row" style={{marginTop: '20px'}}>
                <label className="label" htmlFor="input-data-schedule">
                Data Inicial:
                </label>
                <DatePicker
                className="control"
                customInput={
                    <InputMask
                    mask="99/99/9999"
                    value={startDate} />
                }
                selected={startDate}
                onChange={(date) => {
                    this.setState({
                        startDate: date
                    }, () => {this.loadStats(user)})
                }}
                showTimeSelect={false}
                name="startDate"
                dateFormat="dd/MM/yyyy"
                locale="pt"
                />
                
                <label className="label" htmlFor="input-data-schedule">
                Data Final:
                </label>
                <DatePicker
                className="control"
                customInput={
                    <InputMask
                    mask="99/99/9999"
                    value={endDate} />
                }
                selected={endDate}
                onChange={(date) => {
                    this.setState({
                        endDate: date
                    }, () => {this.loadStats(user);})
                }}
                showTimeSelect={false}
                name="endDate"
                dateFormat="dd/MM/yyyy"
                locale="pt"
                />
            </div>    
            <div className="control" style={{marginTop: '20px'}}>
              <div className="select">
                <select
                  name='clients'
                  onChange={this.handleClientChange}
                  value={this.state.selectedStatsIndex}
                >
                  {statsList.map((stat, index) => 
                    <option value={index}>{stat.clientName}</option>
                  )}
                </select>
              </div>
            </div>
            </Collapsible>
            <S.Line/>
            {selectedStats &&<S.StatsPanel>
            <S.IdleResources  color={selectedStats.idleResources > 0 ? "#ff3860" : "#3FC380"}>
                  <S.IdleResourcesTitle>Frotas Paradas</S.IdleResourcesTitle>
                  <S.IdleResourcesData>{selectedStats.idleResources}</S.IdleResourcesData>
              </S.IdleResources>
              <PieChart
                lineWidth={30}  
                labelPosition={112}
                label={({ dataEntry }) => dataEntry.percentage > 0 && dataEntry.percentage.toFixed(2) + '%'  }
                animate={true}
                data={[
                  { title: 'Fechado', value: selectedStats.doneCount, color: '#3FC380' },
                  { title: 'Em Andamento',  value:selectedStats.inProgressCount, color: '#0c65a1' },
                  { title: 'Aberto', value: selectedStats.openCount, color: '#ff3860' },
                ]}
              
                labelStyle={{
                  fontSize: '0.5em',
                  fontFamily: 'sans-serif',
                  padding: '20px'
                }}
              />
              <S.NumberDataContainer onClick={() => this.filterByStatus('ABERTO')} color="#ff3860">
                <S.NumberDataTitle>Abertos</S.NumberDataTitle>
                <S.NumberData>{selectedStats.openCount}</S.NumberData>
              </S.NumberDataContainer>
              <S.NumberDataContainer onClick={() => this.filterByStatus('EM_ATENDIMENTO')} color="#0c65a1">
                <S.NumberDataTitle>Em Andamento</S.NumberDataTitle>
                <S.NumberData>{selectedStats.inProgressCount}</S.NumberData>
              </S.NumberDataContainer>
              <S.NumberDataContainer onClick={() => this.filterByStatus('CONCLUIDO')} color="#3FC380">
                <S.NumberDataTitle>Fechados</S.NumberDataTitle>
                <S.NumberData>{selectedStats.doneCount}</S.NumberData>
              </S.NumberDataContainer>
              <S.NumberDataContainer onClick={() => this.filterByStatus(null)} >
                <S.NumberDataTitle>Total</S.NumberDataTitle>
                <S.NumberData>{selectedStats.total}</S.NumberData>
              </S.NumberDataContainer>
            </S.StatsPanel>}
            <S.Line/>
          </S.HeaderContainer>

        </S.Header>
        {idleResources.length > 0 && <S.DataTableContainer>
          <DataTable
              title="Frotas Paradas"
              columns={idleResourceColumns}
              data={idleResources}
              pagination
              paginationServer
              highlightOnHover
              responsive
              paginationComponentOptions = {paginationComponentOptions}
              rangeSeparatorText = "de"
              paginationTotalRows={idleResourceTotalRows}
              onChangePage={this.handlePageChange}
              striped
          />
        </S.DataTableContainer>}
        <S.DataTableContainer>
          <DataTable
              title="Chamados"
              noDataComponent="Nenhum chamado encontrado para o período informado"
              columns={columns}
              data={serviceResquests}
              conditionalRowStyles={conditionalRowStyles}
              pagination
              paginationServer
              highlightOnHover
              responsive
              paginationComponentOptions = {paginationComponentOptions}
              rangeSeparatorText = "de"
              paginationTotalRows={totalRows}
              onChangePage={this.handlePageChange}
          />
        </S.DataTableContainer>
      </div>
      </Fragment>
    )
  }
}