import React, { Component, Fragment } from 'react'
import { Navbar } from '../../components'
import HelpdeskService from '../../services/helpdesk/helpdesk-service'
import { SweetAlert } from '../../components/index'
import ImageUploader from 'react-images-upload';

import '../../assets/css/custombulma.css'
import 'font-awesome/css/font-awesome.min.css'

export class Helpdesk extends Component {

  constructor(props) {
    super(props)

    this.state = {
      type: 'P',
      description : '',
      priority : 'MEDIUM',
      image: {}
    }
  }
  
  componentDidMount() {
    this.helpdeskService = new HelpdeskService()
    document.querySelector('.loader').style.visibility = 'hidden'
  }
  
  saveResourceService = async () => {
    let {
      type,
      description,
      priority,
      image
    } = this.state

    let helpdesk = {
      type,
      description,
      priority
    }

    try {
      await this.helpdeskService.add(helpdesk, image)
      SweetAlert.confirmSucess('Seu registro foi criado com sucesso!')
    } catch (e) {
      console.log(e)
    }
  }

  
  handleChange = (event) => {

    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  onDrop = (selectedImage) => {
      console.log(selectedImage)
      this.setState({
        image: selectedImage[0],
      });
  }

  render() {
    const {
      type,
      description,
      priority,
      image
    } = this.state

    return (
      <Fragment>
        <Navbar />
        <div style={{ paddingTop: 55, }}>
          <section className="section is-bold">
            <h1 class="title">Reportar Problema ou Enviar Sugestão</h1>
            <h2 class="subtitle">Aplicativos Carmak</h2>
            
            <div className="columns">

              <div className="field column">
                <label className="label">Tipo:</label>
                <div className="control">
                  <div className="select">
                    <select
                      name='type'
                      value={type}
                      onChange={this.handleChange}
                    >
                      <option value="P">Problema</option>
                      <option value="S">Sugestão</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {type == 'P' && <div className="columns">

              <div className="field column">
                <label className="label">Prioridade:</label>
                <div className="control">
                  <div className="select">
                    <select
                      name='priority'
                      value={priority}
                      onChange={this.handleChange}
                    >
                      <option value="VERY_HIGH">Muito Alta</option>
                      <option value="HIGH">Alta</option>
                      <option value="MEDIUM">Média</option>
                      <option value="LOW">Baixa</option>
                      <option value="VERY_LOW">Muito Baixa</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>}

            <div className="field">
              <label className="label">Descrição</label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Descrição do problema/sugestão"
                  name="description"
                  value={description}
                  minLength="10"
                  maxLength="500"
                  onChange={this.handleChange}
                >
                </textarea>
              </div>
            </div>

            <div className="columns">

            <div className="field column">
                <label className="label">Imagem: </label>
             
                <input
                  min="0"
                  className="input"
                  type="text"
                  name="hourMeter"
                  value={image.name}
                  onChange={this.handleChange}
                />
      
                <ImageUploader
                  withIcon={false}
                  singleImage={true}
                  buttonText='Selecionar imagem'
                  onChange={this.onDrop}
                  imgExtension={['.jpg', '.gif', '.png', '.gif']}
                  maxFileSize={5242880}
                  label='Tamanho máximo: 5mb, Formatos: .jpg, .gif, .png, .gif'
              />
           
            </div>
  

            </div>
            {
              !description || description.length < 10 ?
                <div>
                  <button
                    className="button is-primary is-pulled-right is-normal"
                    title="Disabled button"
                    disabled
                  >
                    Salvar
                </button>
                </div>
                :
                <div className="field" >
                  <button
                    onClick={this.saveResourceService}
                    className="button is-primary is-pulled-right is-normal"
                    title="Disabled button"
                  >
                    Salvar
                </button>
                </div>
            }
          </section>
        </div>
      </Fragment>
    )
  }
}