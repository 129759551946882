import Swal from 'sweetalert2'
import { redirectToLogin } from '../../utils/auth';

const COR_BOTAO = '#02592E'

export class SweetAlert {

  static simpleSucess() {
    Swal.fire({
      position: 'top-end',
      type: 'success',
      toast: true,
      showConfirmButton: false,
      text: 'Sucesso!',
      timer: 1500
    })
  }

  static connectionFailed(message) {
    const fullText = message ? `Falha de conexão, tente novamente. [${message}]` : 'Falha de conexão, tente novamente.'

    Swal.fire({
      type: 'error',
      confirmButtonColor: COR_BOTAO,
      title: 'Erro',
      text: fullText 
    })
  }

  static notAuthorized() {
    Swal.fire({
      type: 'info',
      confirmButtonColor: COR_BOTAO,
      title: 'Falha de autorização',
      text: 'Usuário não autorizado ou login expirado.'
    }).then(function() {
      redirectToLogin()
    })
  }

  static error(mensagem) {
    Swal.fire({
      type: 'error',
      confirmButtonColor: COR_BOTAO,
      title: 'Erro',
      text: mensagem
    })
  }

  static confirmSucess(msg) {
    Swal.fire({
      type: 'success',
      confirmButtonColor: COR_BOTAO,
      title: msg,
    })
  }
}
