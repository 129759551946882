export const msalConfig = {
  auth: {
    clientId: "f5854e67-ab70-4c23-9ba5-6c7a3dc7cb79",
    authority: "https://login.microsoftonline.com/27951710-ebb9-4efb-a4a5-48e460822d4b", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://chamados.carmak.com.br/frotas",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};